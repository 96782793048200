// Page components used on overlay pages, such as cards

import { faUserLarge } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, ReactNode, HTMLAttributes, forwardRef } from "react";
import { Link } from "react-router-dom";
import LoadingSpinnerInline from "./spinners";
import { cn } from "../lib/text-utils";

export interface IOverviewCardProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  title: string;
}

export const OverviewCard = forwardRef<HTMLDivElement, IOverviewCardProps>(
  ({ className, children, title, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          "px-5 pt-3 pb-4 rounded-lg shadow-md bg-slate-50 dark:bg-slate-600 shadow-gray/50 dark:shadow-gray/80",
          className
        )}
        {...props}
      >
        <h2 className="pb-3 text-xl font-bold">{title}</h2>
        {children}
      </div>
    );
  }
);

export interface IUserTileSmallProps extends HTMLAttributes<HTMLDivElement> {
  user: Pick<IUser, "id" | "first_name" | "last_name" | "gravatar_hash">;
}

export const UserTileSmall = forwardRef<HTMLDivElement, IUserTileSmallProps>(
  ({ className, user, ...props }, ref) => {
    // Get user's Gravatar hash from the email
    return user ? (
      <Link to={`/users/${user.id}`}>
        <div
          ref={ref}
          className={cn(
            "flex flex-col items-center h-full px-3 py-2 transition border rounded-lg shadow-md grow border-slate-400 dark:border-slate-700 hover:border-white hover:ring-4 hover:ring-blue-400 shadow-gray-200 dark:shadow-gray-800 hover:cursor-pointer",
            className
          )}
          {...props}
        >
          <div className="flex items-center justify-center w-16 h-16 border rounded-full border-slate-300 dark:border-slate-700">
            {user.gravatar_hash ? (
              <img
                className="rounded-full"
                src={`https://gravatar.com/avatar/${user.gravatar_hash}?s=64`}
              />
            ) : (
              <FontAwesomeIcon
                className="w-8 h-8 text-slate-400"
                icon={faUserLarge}
              />
            )}
          </div>
          <div className="mt-2 italic leading-tight text-center text-slate-500 dark:text-slate-300">
            {user.first_name} {user.last_name}
          </div>
        </div>
      </Link>
    ) : (
      <LoadingSpinnerInline />
    );
  }
);
