// Outlet view for the detail page of a datafile. Can show a preview of the
// datafile along with a link to download a copy of it.

import {
  faDownload,
  faPencil,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { GenericButton } from "../../../components/buttons";
import { CreatedModifiedAtByIndicator } from "../../../components/message-indicators";
import { OverviewCard } from "../../../components/overview-page-components";
import LoadingSpinnerInline from "../../../components/spinners";
import { SimpleControlledModalDialog } from "../../../components/tables/row-edit-buttons";
import { useApiModelDetailView } from "../../../lib/api-mutate-hooks";
import useToken, { useFullCurrentUser } from "../../../lib/auth";
import { formatBytes } from "../../../lib/text-utils";
import { ExperimentContext } from "../experiment-detail";

export default function ExperimentDetailDatafilesDetailOutlet() {
  // Get datafile ID from the URL
  const params = useParams<{ datafileId: string }>();
  const datafileId = params.datafileId
    ? parseInt(params.datafileId)
    : undefined;

  // Get the JSON metadata for this datafile
  const {
    data: dataFile,
    error: dataFileError,
    destroySingleModelInstance: destroySingleDatafile,
  } = useApiModelDetailView<IDataFile>({
    id: datafileId,
    resourceName: "datafiles",
    shouldGetData: !!datafileId,
  });

  // Also want the experiment context
  const { experiment, experiment_error, experiment_mutate } =
    useContext(ExperimentContext);

  // Also need the current user's token to pass as the query param to allow the
  // browser to download the file.
  const { token } = useToken();

  // Also need full user profile
  const { fullUser, fullUserError } = useFullCurrentUser({
    shouldGetData: true,
  });

  // Also get experiment context

  // State for modal delete dialog
  const [showDestroyCharResultModal, setShowDestroyCharResultModal] =
    useState(false);

  // Have navigators
  const navigate = useNavigate();

  return (
    <div className="container">
      {fullUser && !fullUserError && experiment && !experiment_error ? (
        <SimpleControlledModalDialog
          isOpen={showDestroyCharResultModal}
          setIsOpen={setShowDestroyCharResultModal}
          title="Really delete this data file?"
          actionLabel="Yes, delete it"
          confirmHandler={async () => {
            await destroySingleDatafile();
            await experiment_mutate();
            navigate(`/experiments/${experiment.id}/datafiles`);
          }}
        >
          <p>You'll lose this data file and all associated metadata</p>
          <p className="mt-2 font-semibold">This can't be undone.</p>
        </SimpleControlledModalDialog>
      ) : null}
      {experiment && !experiment_error && dataFile && !dataFileError ? (
        <>
          <h2 className="text-xl font-bold">
            File details: {dataFile.filename}
          </h2>
          <div className="grid grid-cols-1 mt-4 lg:grid-cols-2">
            <OverviewCard title="File info">
              <div>
                <span className="font-bold">File name:</span>{" "}
                <span>
                  <a
                    href={`${dataFile.file_instance}?token=${token}`}
                    className="font-semibold link"
                    rel="noreferrer noopener"
                  >
                    {dataFile.filename}
                    <FontAwesomeIcon
                      icon={faDownload}
                      className="w-4 h-4 ml-3"
                    />
                  </a>
                </span>
              </div>
              <div>
                <span className="font-bold">Size:</span>{" "}
                <span>{formatBytes(dataFile.size, 2)}</span>
              </div>
              <div>
                <span className="font-bold">ID:</span>{" "}
                <span>{dataFile.id}</span>
              </div>
              <div>
                <span className="font-bold">Description:</span>{" "}
                <span>{dataFile.description}</span>
              </div>
              {fullUser &&
              !fullUserError &&
              fullUser.my_projects.includes(experiment.project_explicit_id) ? (
                <div className="flex items-center mt-3 gap-x-2">
                  <Link to="edit">
                    <GenericButton
                      accentedButton
                      icon={<FontAwesomeIcon icon={faPencil} />}
                      className="h-8 px-2 py-0"
                    >
                      Edit
                    </GenericButton>
                  </Link>
                  <GenericButton
                    icon={<FontAwesomeIcon icon={faTrash} />}
                    className="h-8 px-2 py-0 bg-red-400 dark:bg-red-600/50 "
                    onClick={() => {
                      setShowDestroyCharResultModal(true);
                    }}
                  >
                    Delete
                  </GenericButton>
                </div>
              ) : null}
              <CreatedModifiedAtByIndicator
                object={dataFile}
                className="mt-4"
              />
            </OverviewCard>
          </div>
        </>
      ) : (
        <LoadingSpinnerInline />
      )}
    </div>
  );
}
