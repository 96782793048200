// Want an outlet page that will show all files of the experiment that are also
// associated with the current characterization result.

import {
  faCircleInfo,
  faDownload,
  faPlus,
  faUpload,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createColumnHelper } from "@tanstack/table-core";
import { Form, Formik } from "formik";
import { HTMLAttributes, forwardRef, useContext, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { KeyedMutator } from "swr";
import * as Yup from "yup";
import { GenericButton, SubmitButton } from "../../../components/buttons";
import MyDropzone, {
  IFileToUpload,
} from "../../../components/file-upload-dropzone";
import { MyMultiSelectInput } from "../../../components/formik-react-select";
import {
  SimpleToastControlled,
  StorageUsedIndicator,
} from "../../../components/message-indicators";
import LoadingSpinnerInline from "../../../components/spinners";
import { SimpleConfirmActionButton } from "../../../components/tables/row-edit-buttons";
import TanstackTableFilterableSortable from "../../../components/tables/tanstack-table-wrappers";
import {
  useApiModelDetailView,
  useApiModelListView,
} from "../../../lib/api-mutate-hooks";
import useToken, { useFullCurrentUser } from "../../../lib/auth";
import { MyDateTimeFormat } from "../../../lib/date-utils";
import { fetcherMultipartFormdata, fetcherPatch } from "../../../lib/fetcher";
import { cn, formatBytes, truncate } from "../../../lib/text-utils";
import { ExperimentContext } from "../experiment-detail";

const handleSingleFileUpload: (
  // This is ugliness. Basically, take the properties of the handleUpload
  // function type's object argument from IMyDropzone, and allow passing a
  // third property that is the owning experiment's ID.
  arg0: {
    fileToUpload: IFileToUpload;
    owning_experiment_id: number;
    owning_char_result: number;
  }
) => Promise<void> = async ({
  fileToUpload,
  owning_experiment_id,
  owning_char_result,
}) => {
  // POST to the datafile creation endpoint, choosing sensible defaults for
  // the required fields in the DataFile model.
  let formData = new FormData();
  formData.append("description", fileToUpload.file.name);
  formData.append("file_instance", fileToUpload.file);
  formData.append("owning_experiment", String(owning_experiment_id));

  // Await uploaded file, then get its newly assigned ID
  const uploadedFile = await fetcherMultipartFormdata<IDataFile>(
    `${import.meta.env.VITE_API_URL}/api/v1/datafiles/`,
    formData,
    "POST"
  );

  // Also now want to associate this file ID with the current characterization result.
  if (uploadedFile) {
    await fetcherPatch<{}, ISubmitCharDatafileChangesResult>(
      `${
        import.meta.env.VITE_API_URL
      }/api/v1/characterization_results/${owning_char_result}/associate_datafiles/?file_ids=${
        uploadedFile?.id
      }`,
      {},
      "POST"
    );
  } else {
    console.log(
      "Problem uploading file and associating it with characterization result. See if it is available within the experiment to be added manually."
    );
  }

  return undefined;
};

export default function SingleCharDatafilesOutlet() {
  // Need to get the current characterization result.
  const params = useParams<{ charId: string }>();
  const charId = params.charId ? parseInt(params.charId) : undefined;

  // Get single characterization result
  const {
    data: charResult,
    error: charResultError,
    mutate: charResultMutate,
  } = useApiModelDetailView<ICharacterizationResult>({
    resourceName: "characterization_results",
    shouldGetData: !!charId,
    id: charId,
  });

  // Reuse experiment SWR call
  const { experiment, experiment_error, experiment_mutate } =
    useContext(ExperimentContext);

  // Dependent query to get datafile info for the experiment.
  const {
    data: dataFiles,
    error: dataFilesError,
    mutate: listMutate,
  } = useApiModelListView<IDataFile>({
    resourceName: "datafiles",
    shouldGetData: !!(
      charResult &&
      !charResultError &&
      charResult.files &&
      charResult.files.length > 0
    ),
    queryParams: { id__in: String(charResult?.files) },
  });

  const dataFilesList = dataFiles && !dataFilesError ? dataFiles : [];

  // Also need list of datafiles for full experiment, so we can show total
  // storage used for all of the experiment's files.
  // Dependent query to get datafile info for the experiment.
  const { data: experimentDataFiles, error: experimentDataFilesError } =
    useApiModelListView<IDataFile>({
      resourceName: "datafiles",
      shouldGetData: !!(
        experiment &&
        !experiment_error &&
        experiment.files_of_experiment &&
        experiment.files_of_experiment.length > 0
      ),
      queryParams: { id__in: String(experiment?.files_of_experiment) },
    });

  // Also need full user
  const { fullUser, fullUserError } = useFullCurrentUser({
    shouldGetData: true,
  });

  // And need the token for direct download links
  const { token } = useToken();

  // Set up table columns for tabular display
  const columnHelper = createColumnHelper<IDataFile>();
  const columns = [
    columnHelper.accessor("filename", {
      header: () => "File name",
      cell: (info) => (
        <Link
          to={`/experiments/${experiment!.id}/datafiles/${
            info.row.original.id
          }/`}
          className="font-semibold link"
        >
          {info.getValue()}
        </Link>
      ),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor("size", {
      header: () => "Size",
      cell: (info) => formatBytes(info.row.original.size, 2),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor("modified_at", {
      header: () => "Modified",
      cell: (info) => (
        <MyDateTimeFormat theDateTime={info.getValue()} showDistance={false} />
      ),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor("description", {
      header: () => "Description",
      cell: (info) => info.getValue(),
      footer: (info) => info.column.id,
    }),
    columnHelper.display({
      id: "download-btn",
      header: () => "Download",
      cell: (info) => (
        <a
          href={`${info.row.original.file_instance}?token=${token}`}
          className="mx-auto font-semibold link"
          rel="noreferrer noopener"
        >
          <FontAwesomeIcon icon={faDownload} className="w-4 h-4" />
        </a>
      ),
      footer: (info) => info.column.id,
      size: 30,
    }),
    columnHelper.display({
      id: "delete_btns",
      header: () => "Actions",
      cell: (info) => (
        <div className="flex justify-center">
          <SimpleConfirmActionButton
            className="w-24"
            label="Remove"
            onClick={async () => {
              if (charId) {
                await submitFileChanges({
                  char_id: charId,
                  datafile_ids: [info.row.original.id],
                  operation: "remove",
                });
                await charResultMutate();
              }
            }}
          />
        </div>
      ),
      footer: (info) => info.column.id,
      size: 30,
    }),
  ];

  // Calculate total file size, including data files that aren't part of this
  // characterization result.
  const totalFileSize =
    experimentDataFiles && !experimentDataFilesError
      ? experimentDataFiles
          .map((dataFile, _) => dataFile.size)
          .reduce((a, b) => a + b, 0)
      : 0;

  const MAX_TOTAL_FILESIZE = experiment ? experiment.max_file_storage_bytes : 1;

  // Have state to decide whether to show file upload dropzone
  const [showFileUploadDropzone, setShowFileUploadDropzone] = useState(false);

  // Also determine whether user should be shown the edit buttons
  const userCanEditCharResult = !!(
    experiment &&
    !experiment_error &&
    fullUser &&
    !fullUserError &&
    fullUser.my_projects.includes(experiment.project_explicit_id)
  );

  return (
    <div className="container">
      {charId &&
      charResult &&
      !charResultError &&
      experiment &&
      !experiment_error ? (
        <>
          <h2 className="text-xl font-bold">
            Files associated with characterization result "{charResult?.name}"
          </h2>
          {userCanEditCharResult ? (
            <div className="flex mt-4">
              <GenericButton
                accentedButton={!showFileUploadDropzone}
                icon={
                  <FontAwesomeIcon
                    icon={showFileUploadDropzone ? faXmark : faUpload}
                  />
                }
                onClick={() => setShowFileUploadDropzone((prev) => !prev)}
              >
                {showFileUploadDropzone
                  ? "Hide file uploader"
                  : "Upload New Files"}
              </GenericButton>
            </div>
          ) : null}
          {showFileUploadDropzone ? (
            <MyDropzone
              handleSingleFileUpload={async ({ fileToUpload }) => {
                await handleSingleFileUpload({
                  fileToUpload,
                  owning_experiment_id: experiment.id,
                  owning_char_result: charId,
                });

                // Now mutate both the characterization result and its parent experiment
                await charResultMutate();
                await experiment_mutate();
              }}
              maxAllowableTotalFileSize={experiment.max_file_storage_bytes}
              maxIndividualFileSize={experiment.max_individual_file_bytes}
            />
          ) : null}
          {userCanEditCharResult ? (
            !!(
              charId &&
              charResult &&
              !charResultError &&
              experiment &&
              !experiment_error
            ) ? (
              <AddDatafileSingleLineForm
                charResultMutate={charResultMutate}
                char_id={charId}
                characterization={charResult}
                experiment_mutate={experiment_mutate}
                current_datafiles={dataFilesList}
                className="mt-4"
              />
            ) : (
              <LoadingSpinnerInline />
            )
          ) : null}
          <div className="mt-6">
            {dataFiles && !dataFilesError ? (
              <TanstackTableFilterableSortable
                columns={columns}
                data={dataFiles}
                initialColumnVisibility={{
                  delete_btns: userCanEditCharResult,
                }}
              />
            ) : (
              <span className="italic text-slate-600 dark:text-slate-300">
                (No files associated with this characterization result yet.)
              </span>
            )}
          </div>
          <StorageUsedIndicator
            totalFileSize={totalFileSize}
            maxTotalFileSize={MAX_TOTAL_FILESIZE}
          />
          {userCanEditCharResult && dataFiles && dataFiles.length > 0 ? (
            <span className="block mt-2 italic text-slate-600 dark:text-slate-300">
              Removing files from this characterization result does not delete
              them permanently and doesn't reduce the total per-experiment file
              storage used. To delete files permanently, click the file's name
              to go to its info page, then delete it from there.
            </span>
          ) : null}
        </>
      ) : (
        <div className="flex items-center justify-center">
          <LoadingSpinnerInline />
        </div>
      )}
    </div>
  );
}

interface ISubmitCharDatafileChanges {
  char_id: number;
  datafile_ids: number[];
  operation: "add" | "remove";
}

interface ISubmitCharDatafileChangesResult {
  detail: string;
}

export interface IAddDatafileSingleLineForm
  extends HTMLAttributes<HTMLFormElement> {
  characterization: ICharacterizationResult;
  current_datafiles: IDataFile[];
  char_id: number;
  charResultMutate: KeyedMutator<ICharacterizationResult>;
  experiment_mutate: KeyedMutator<IExperiment>;
}

export const submitFileChanges: (
  arg0: ISubmitCharDatafileChanges
) => Promise<ISubmitCharDatafileChangesResult | undefined> = async ({
  char_id,
  datafile_ids,
  operation,
}) => {
  const file_id_str = datafile_ids.join(",");
  console.log(
    "Going to associate datafile IDs",
    file_id_str,
    "with char result",
    char_id
  );
  try {
    return await fetcherPatch<{}, ISubmitCharDatafileChangesResult>(
      `${
        import.meta.env.VITE_API_URL
      }/api/v1/characterization_results/${char_id}/associate_datafiles/?file_ids=${file_id_str}`,
      {},
      operation === "add" ? "POST" : "DELETE"
    );
  } catch (error) {
    console.log(error);
    return { detail: "request-error" };
  }
};

const AddDatafileSingleLineForm = forwardRef<
  HTMLFormElement,
  IAddDatafileSingleLineForm
>(
  (
    {
      className,
      current_datafiles,
      char_id,
      characterization,
      charResultMutate,
      experiment_mutate,
      ...props
    },
    ref
  ) => {
    const [showForm, setShowForm] = useState(false);

    // Get data files available in the experiment to be added to this
    // characterization result.
    const { experiment, experiment_error } = useContext(ExperimentContext);

    const { data: allFiles, error: allFilesError } =
      useApiModelListView<IDataFile>({
        resourceName: "datafiles",
        shouldGetData: !!(experiment && !experiment_error),
        fields: ["id", "filename", "size", "description"],
        queryParams: { id__in: String(experiment?.files_of_experiment) },
      });

    const current_datafile_ids = current_datafiles.map(
      (datafile) => datafile.id
    );
    const datafileChoices = allFiles
      ? allFiles
          .filter((datafile) => !current_datafile_ids.includes(datafile.id))
          .map((datafile) => ({
            id: datafile.id,
            name: `${datafile.filename} - ${formatBytes(datafile.size)} ${
              datafile.description
                ? `(${truncate(datafile.description, 40)})`
                : ""
            }`,
          }))
      : [];

    // Set up little Formik form for adding new group members
    const [formKey, setFormKey] = useState(1);
    const initialValues = { datafiles_to_add: [] };
    const validationSchema = Yup.object().shape({
      datafiles_to_add: Yup.array()
        .of(Yup.number())
        .required("Must specify datafile(s) to add")
        .min(
          1,
          "Must specify at least one datafile to associate with this characterization result."
        ),
    });

    const [resultMsg, setResultMsg] = useState("");
    const resultMsgHash: { [x: string]: string } = {
      ok: `Successfully edited file list for this characterization result!`,
      "One or more of the specified files does not exist.":
        "One or more of the specified files does not exist.",
      "request-error":
        "There was an error processing your request. Please try again later.",
    };
    const [toastVisible, setToastVisible] = useState(false);

    return allFiles && !allFilesError ? (
      <>
        <SimpleToastControlled
          message={resultMsg}
          icon={<FontAwesomeIcon icon={faCircleInfo} />}
          isVisible={toastVisible}
          setIsVisible={setToastVisible}
          className="mx-auto"
        />
        <Formik
          key={formKey}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            const datafile_ids = values.datafiles_to_add;
            const result = await submitFileChanges({
              datafile_ids,
              char_id,
              operation: "add",
            });

            // Now mutate both the characterization result and its parent experiment
            await charResultMutate();
            await experiment_mutate();

            setSubmitting(false);
            console.log("Got response", result);

            const resultsMsg =
              result && result.detail
                ? resultMsgHash[result.detail]
                  ? resultMsgHash[result.detail]
                  : "Unknown response message"
                : "Unknown error";

            setResultMsg(resultsMsg);
            setToastVisible(true);
            setShowForm(false);
          }}
          onReset={(values) => {
            values = initialValues;
          }}
        >
          {({ isSubmitting, ...formikFormprops }) => (
            <Form ref={ref} {...formikFormprops} {...props}>
              <div
                className={cn(
                  "gap-y-2 flex",
                  className,
                  showForm ? "flex-col" : ""
                )}
              >
                {showForm ? (
                  <>
                    <MyMultiSelectInput
                      choices={datafileChoices}
                      label="Existing experiment files to associate with this characterization result"
                      name="datafiles_to_add"
                      type="number"
                    />
                    <div className="flex gap-x-4">
                      <SubmitButton isSubmitting={isSubmitting} />
                      <GenericButton type="reset" accentedButton={false}>
                        Clear
                      </GenericButton>
                      <GenericButton
                        type="reset"
                        onClick={() => {
                          // Want to both close the form and force it to reset.
                          setShowForm(false);
                          setFormKey((key) => key + 1);
                        }}
                      >
                        Close
                      </GenericButton>
                    </div>
                  </>
                ) : (
                  <GenericButton
                    icon={<FontAwesomeIcon icon={faPlus} />}
                    accentedButton
                    onClick={() => setShowForm(true)}
                  >
                    Add existing files to this characterization result
                  </GenericButton>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </>
    ) : (
      <LoadingSpinnerInline />
    );
  }
);
