// Tailwind UI navbar

import { Fragment, useContext, useEffect } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import {
  BellIcon,
  Bars3Icon,
  XMarkIcon,
  SunIcon,
  MoonIcon,
} from "@heroicons/react/24/outline";
import { Link, NavLink, useNavigate } from "react-router-dom";
import useToken, {
  ILogoutUserArgs,
  logoutUser,
  useCurrentKnoxUser,
} from "../lib/auth";
import GlobalStateContext from "../lib/globalStateContext";
import useSWR from "swr";
import { Sha256 } from "@aws-crypto/sha256-browser";
import { sleepAsync, truncate } from "../lib/text-utils";

const navigation = [
  { name: "Groups", href: "/groups", current: false },
  { name: "Institutions", href: "/institutions", current: false },
  { name: "Users", href: "/users", current: false },
  { name: "Projects", href: "/projects", current: false },
  { name: "Experiments", href: "/experiments", current: false },
  { name: "Publications", href: "/publications", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar({ themeSetter }) {
  const [theme, setTheme] = themeSetter;

  // Session token
  const { token } = useToken();

  // Get current user
  const { currentKnoxUser, currentKnoxUserError, mutate } = useCurrentKnoxUser({
    shouldGetData: true,
  });

  // Also get full user record so we have access to the Gravatar hash
  const { data: fullUser, error: fullUserError } = useSWR<IUser>(() =>
    currentKnoxUser
      ? `${import.meta.env.VITE_API_URL}/api/v1/users/${currentKnoxUser.pk}/`
      : null
  );

  // Handle logout action
  const navigate = useNavigate();
  const handleLogout = async (args: ILogoutUserArgs) => {
    await logoutUser(args);
    sessionStorage.removeItem("nextLocation");
    mutate();
    navigate("/home");
  };

  // Check if token is invalid. If so, want to clear it and navigate to login

  useEffect(() => {
    async function operation() {
      await sleepAsync(200);
      if (
        (currentKnoxUser && currentKnoxUser.detail === "Invalid token.") ||
        currentKnoxUserError
      ) {
        // Token is invalid. Remove session storage entry, which should cause
        // component to redirect to login page.
        console.log(
          "Detected invalid token; clearing and going to login screen."
        );
        localStorage.removeItem("token");
        navigate("/login");
      }
    }
    operation();
  }, [currentKnoxUser, currentKnoxUserError]);

  return (
    <Disclosure
      as="nav"
      className="z-40 flex-none shadow-md bg-slate-800 shadow-black/50"
    >
      {({ open }) => (
        <>
          <div className="px-2 mx-auto max-w-7xl sm:px-6 lg:px-8 ">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block w-6 h-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block w-6 h-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex items-center justify-center flex-1 lg:items-stretch lg:justify-start">
                <Link to="/home">
                  <div className="flex items-center flex-shrink-0">
                    <img
                      className="self-start block h-auto w-7 lg:w-auto lg:h-8"
                      src="/favicon.png"
                      alt="Workflow"
                    />
                    <div className="hidden w-auto h-8 ml-3 text-2xl font-bold text-white xl:block">
                      Metal Alloy Database
                    </div>
                  </div>
                </Link>
                <div className="hidden sm:block sm:ml-6">
                  <div className="flex items-center">
                    {navigation.map((item) => (
                      <NavLink
                        key={item.name}
                        to={item.href}
                        className={({ isActive }) =>
                          classNames(
                            isActive
                              ? "bg-gray-900 text-white"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "px-3 py-2 rounded-md text-sm font-medium text-center"
                          )
                        }
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </NavLink>
                    ))}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {/* Theme switcher */}
                <button
                  type="button"
                  className="p-1 ml-1 text-gray-400 bg-gray-800 rounded-full sm:ml-3 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                  onClick={() => setTheme(theme === "light" ? "dark" : "light")}
                >
                  <span className="sr-only">Light/Dark Theme</span>
                  {theme === "dark" ? (
                    <SunIcon className="w-6 h-6" aria-hidden="true" />
                  ) : (
                    <MoonIcon className="w-6 h-6" aria-hidden="true" />
                  )}
                </button>
                <button
                  type="button"
                  className="p-1 ml-1 text-gray-400 bg-gray-800 rounded-full sm:ml-3 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="w-6 h-6" aria-hidden="true" />
                </button>

                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="flex ml-1 text-sm bg-gray-800 rounded-full sm:ml-3 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                      <span className="sr-only">Open user menu</span>
                      {!currentKnoxUserError &&
                      currentKnoxUser?.first_name &&
                      typeof token === "string" ? (
                        <div className="flex items-center gap-x-4">
                          {fullUser && !fullUserError ? (
                            <img
                              className="rounded-full"
                              src={`https://gravatar.com/avatar/${fullUser.gravatar_hash}?s=32`}
                            />
                          ) : (
                            <div className="flex items-center justify-center w-8 h-8 bg-blue-300 rounded-full">
                              <div className="text-xl">
                                {`${currentKnoxUser.first_name[0]}${currentKnoxUser.last_name[0]}`}
                              </div>
                            </div>
                          )}

                          <div className="text-sm font-medium text-gray-300">
                            {/* Show first name, truncated at 15 chars to avoid layout issues. */}
                            {truncate(currentKnoxUser.first_name, 15)}
                          </div>
                        </div>
                      ) : (
                        <div className="flex items-center gap-x-4">
                          <img
                            className="w-8 h-8 rounded-full"
                            src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                            alt=""
                          />
                          <div className="text-sm font-medium text-gray-300">
                            Log In
                          </div>
                        </div>
                      )}
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      className={`absolute ${
                        currentKnoxUser && !currentKnoxUserError
                          ? "right-0"
                          : "right-0"
                      } w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
                    >
                      {typeof token === "string" ? (
                        <>
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to={
                                  currentKnoxUser && !currentKnoxUserError
                                    ? `/users/${currentKnoxUser.pk}/`
                                    : "#"
                                }
                              >
                                <div
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  Your Profile
                                </div>
                              </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <div
                                href="#"
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                Settings
                              </div>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <div
                                href="#"
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                                onClick={(e) =>
                                  handleLogout({ type: "this_device" })
                                }
                              >
                                Sign out of this device
                              </div>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <div
                                href="#"
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                                onClick={(e) =>
                                  handleLogout({ type: "all_devices" })
                                }
                              >
                                Sign out of all devices
                              </div>
                            )}
                          </Menu.Item>
                        </>
                      ) : (
                        <>
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to="/login"
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                Sign in
                              </Link>
                            )}
                          </Menu.Item>
                        </>
                      )}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current
                      ? "bg-gray-900 text-white"
                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "block px-3 py-2 rounded-md text-base font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
