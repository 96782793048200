// Want to be able to easily download a CSV file of the available data in a
// Tanstack table.
import { Table } from "@tanstack/react-table";
import { ICsvDownloadProps } from "react-csv-downloader";

export function tableToCsvDownloaderProps<T>(
  table: Table<T>
): ICsvDownloadProps {
  // Get column info of all columns flattened. Thanks to
  // https://stackoverflow.com/a/56544873/11536255 for how to flatten array
  // properly in Typescript.
  const columns = table
    .getHeaderGroups()
    .map((headerGroup) =>
      headerGroup.headers.map((header) => ({
        id: header.id,
        displayName: header.id,
      }))
    )
    .reduce((acc, val) => acc.concat(val), []);

  // Get data from each row
  const datas = table.getFilteredRowModel().rows.map((row, _) => {
    const entries = new Map(
      columns.map((col) => [col.id, String(row.getValue(col.id))])
    );
    return Object.fromEntries(entries);
  });

  // Use timestamp for filename
  const filename = new Date().toISOString();
  return {
    columns,
    datas,
    filename,
    newLineAtEnd: true,
    wrapColumnChar: '"',
    separator: ",",
  };
}
