// Sidebar menu components
// Thanks to https://stackoverflow.com/questions/63297109/nested-sidebar-menu-with-material-ui-and-reactjs
// and https://stackoverflow.com/q/63323112/13558699.

import {
  DocumentIcon,
  FolderIcon,
  MinusCircleIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/outline";
import { CSSProperties, FC, memo, ReactNode, useState } from "react";
import { Link, NavLink, useLocation, useResolvedPath } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faArrowAltCircleRight,
} from "@fortawesome/free-regular-svg-icons";
import {
  faCircleInfo,
  faDiagramProject,
  faMicroscope,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";

export const singleData = [
  {
    icon: <FontAwesomeIcon icon={faCircleInfo} />,
    title: "Overview",
    to: "overview",
    items: [],
  },
  {
    icon: <FontAwesomeIcon icon={faEnvelope} />,
    title: "Messages",
    to: "/messages",
    items: [],
  },
  {
    icon: <FontAwesomeIcon icon={faDiagramProject} />,
    title: "Procedures",
    to: "/procedures",
    items: [],
  },
  {
    icon: <FontAwesomeIcon icon={faMicroscope} />,
    title: "Characterization Results",
    items: [
      {
        title: "Technical Analysis",
        items: [
          {
            title: "The Dow Theory",
            to: "/thedowtheory",
          },
          {
            title: "Charts & Chart Patterns",
            to: "/chart",
          },
          {
            title: "Trend & Trend Lines",
            to: "/trendlines",
          },
          {
            title: "Support & Resistance",
            to: "/sandr",
          },
        ],
      },
      {
        title: "Fundamental Analysis",
        items: [
          {
            title: "The Dow Theory",
            to: "/thedowtheory",
          },
          {
            title: "Charts & Chart Patterns",
            to: "/chart",
          },
          {
            title: "Trend & Trend Lines",
            to: "/trendlines",
          },
          {
            title: "Support & Resistance",
            to: "/sandr",
          },
        ],
      },
      {
        title: "Elliot Wave Analysis",
        items: [
          {
            title: "The Dow Theory",
            to: "/thedowtheory",
          },
          {
            title: "Charts & Chart Patterns",
            to: "/chart",
          },
          {
            title: "Trend & Trend Lines",
            to: "/trendlines",
          },
          {
            title: "Support & Resistance",
            to: "/sandr",
          },
        ],
      },
    ],
  },
];

export const exampleData = Array(1).fill(singleData).flat();

const depthClasses: { [x: number]: string } = {
  1: "pl-3",
  2: "pl-6",
  3: "pl-9",
  4: "pl-12",
};

const SingleLevel: FC<{
  item: ISingleMenuItemSpec;
  depth?: number;
  initialOpen?: boolean;
}> = memo(({ item, depth = 1 }) => {
  return (
    <div className="m-2">
      <NavLink
        to={item?.to || "/404"}
        end={!item?.highlightEdit && item?.to.endsWith("/plots")}
      >
        {({ isActive }) => (
          <div
            className={`${depthClasses[depth]} ${
              isActive ? "bg-blue-200 dark:bg-slate-600" : undefined
            } pr-3 py-2 rounded-lg flex gap-x-3 items-center w-full select-none hover:bg-slate-300 dark:hover:bg-slate-600 transition`}
          >
            <div className="flex items-center justify-center flex-none w-4 h-4">
              {item.icon ? item.icon : <DocumentIcon />}
            </div>
            <div className="text-sm font-semibold">{item.title}</div>
          </div>
        )}
      </NavLink>
    </div>
  );
});

// Before defining the multilevel menu item, we need a patched version of NavLink that doesn't navigate anywhere when clicked.
// Will just copy a lot from react-router.
const NavLinkNoClick: FC<{
  "aria-current"?:
    | boolean
    | "time"
    | "page"
    | "location"
    | "false"
    | "true"
    | "step"
    | "date"
    | undefined;
  caseSensitive?: boolean;
  className?: string | (({ isActive }: { isActive: boolean }) => string);
  end?: boolean;
  style?: ({ isActive }: { isActive: boolean }) => CSSProperties;
  to: string;
  children: ReactNode | (({ isActive }: { isActive: boolean }) => ReactNode);
}> = ({
  "aria-current": ariaCurrentProp = "page",
  caseSensitive = false,
  className: classNameProp = "",
  end = false,
  style: styleProp,
  to,
  children,
}) => {
  let location = useLocation();
  let path = useResolvedPath(to);
  let locationPathname = location.pathname;
  let toPathname = path.pathname;

  if (!caseSensitive) {
    locationPathname = locationPathname.toLowerCase();
    toPathname = toPathname.toLowerCase();
  }

  let isActive =
    locationPathname === toPathname ||
    (!end &&
      locationPathname.startsWith(toPathname) &&
      locationPathname.charAt(toPathname.length) === "/");
  let ariaCurrent = isActive ? ariaCurrentProp : undefined;
  let className;

  if (typeof classNameProp === "function") {
    className = classNameProp({
      isActive,
    });
  } else {
    // If the className prop is not a function, we use a default `active`
    // class for <NavLink />s that are active. In v5 `active` was the default
    // value for `activeClassName`, but we are removing that API and can still
    // use the old default behavior for a cleaner upgrade path and keep the
    // simple styling rules working as they currently do.
    className = [classNameProp, isActive ? "active" : null]
      .filter(Boolean)
      .join(" ");
  }

  let style =
    typeof styleProp === "function"
      ? styleProp({
          isActive,
        })
      : styleProp;

  return (
    <div className={className} style={style} aria-current={ariaCurrent}>
      {typeof children === "function" ? children({ isActive }) : children}
    </div>
  );
};

if (import.meta.env.NODE_ENV !== "production") {
  NavLinkNoClick.displayName = "NavLinkNoClick";
}

const MultiLevelBase: FC<{
  item: IMultiLevelMenuItemSpec;
  depth?: number;
  initialOpen?: boolean;
  isActive: boolean;
}> = memo(({ item, depth = 1, initialOpen = true, isActive = false }) => {
  const { items: children } = item;
  const [open, setOpen] = useState(initialOpen);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  // Try to get menu sections to open automatically when you go to that page
  // from another link elsewhere.
  const location = useLocation();
  useEffect(() => {
    setOpen((prev) => prev || isActive);
  }, [isActive, location.pathname]);

  return (
    <>
      <div className="m-2">
        <div
          className={`${depthClasses[depth]} ${
            isActive ? "bg-gray-200 dark:bg-gray-600" : undefined
          } pr-3 py-2 rounded-lg flex gap-x-3 items-center w-full select-none hover:bg-slate-300 dark:hover:bg-slate-600 transition`}
          onClick={handleClick}
        >
          <div className="flex items-center justify-center flex-none w-4 h-4">
            {item.icon ? item.icon : <FolderIcon />}
          </div>
          <div className="text-sm font-semibold">
            {item.ownLink ? (
              <Link className="group hover:link" to={item.ownLink}>
                {item.title}
                <span className="hidden ml-2 italic group-hover:inline-block opacity-80">
                  <FontAwesomeIcon icon={faArrowAltCircleRight} />
                </span>
              </Link>
            ) : (
              item.title
            )}
          </div>
          <div className="flex-1"></div>
          <div className="flex-none">
            <div className="w-5 h-5">
              {open ? <MinusCircleIcon /> : <PlusCircleIcon />}
            </div>
          </div>
        </div>
      </div>
      <div className={open ? "block" : "hidden"}>
        {children.map((child, key) => (
          <MenuItem
            key={key}
            item={child}
            depth={depth + 1}
            initialOpen={isActive}
          />
        ))}
      </div>
    </>
  );
});

const MultiLevel: FC<{
  item: IMultiLevelMenuItemSpec;
  depth?: number;
  initialOpen?: boolean;
}> = ({ item, depth = 1, initialOpen = true }) => (
  <NavLinkNoClick to={item.to}>
    {({ isActive }) => (
      <MultiLevelBase
        item={item}
        depth={depth}
        isActive={isActive}
        initialOpen={isActive || initialOpen}
      />
    )}
  </NavLinkNoClick>
);

function hasChildren(item: ISingleMenuItemSpec | IMultiLevelMenuItemSpec) {
  if (!("items" in item)) {
    return false;
  }

  // There is an items property; now need to check length
  if (!(item.items.length > 0)) {
    return false;
  }

  // Items property is defined and is array of nonzero length
  return true;
}

export const MenuItem: FC<{
  item: ISingleMenuItemSpec | IMultiLevelMenuItemSpec;
  depth?: number;
  initialOpen?: boolean;
}> = ({ item, depth, initialOpen = false }) => {
  const Component = hasChildren(item) ? MultiLevel : SingleLevel;
  return <Component item={item} depth={depth} initialOpen={initialOpen} />;
};
