// Wrappers for Tanstack react-table, with Tailwind CSS styling for our app.
import {
  faGripLinesVertical,
  faSort,
  faSortDown,
  faSortUp,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  ColumnDef,
  ColumnFiltersState,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  FilterFn,
  filterFns,
  getSortedRowModel,
  Table,
  Column,
  PaginationState,
  VisibilityState,
} from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { cn, pluralizeByLength } from "../../lib/text-utils";
import { RankingInfo } from "@tanstack/match-sorter-utils";
import { fuzzyFilter } from "./tanstack-table-utils";
import { PaginationControls, TableToolbox } from "./tanstack-table-toolbox";
import { TanstackDebouncedInput } from "./tanstack-debounced-input";
import { GenericButton } from "../buttons";

// Alter Tanstack Table declarations to include the sorting and filtering
// functions we'd want.
declare module "@tanstack/table-core" {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
    caseSensitive: FilterFn<unknown>;
    caseInsensitive: FilterFn<unknown>;
  }

  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

interface ITanstackTable<TDataSchema> {
  columns: ColumnDef<TDataSchema>[];
  data: TDataSchema[];
  tableClassName?: string;
  defaultSortColumn?: string;
  showToolbox?: boolean;
  initialColumnVisibility?: VisibilityState;
}

export default function TanstackTableFilterableSortable<TDataSchema>({
  columns: defaultColumns,
  data: defaultData,
  tableClassName = "",
  defaultSortColumn,
  showToolbox: showToolboxDefault = false,
  initialColumnVisibility = undefined,
}: ITanstackTable<TDataSchema>): JSX.Element {
  // State for data in table
  const [data, setData] = useState([...defaultData]);

  // Try to update state whenever initial data changes
  useEffect(() => {
    setData([...defaultData]);
  }, [defaultData]);

  // State for showing the toolbox
  const [showToolbox, setShowToolbox] = useState(showToolboxDefault);

  // State for showing/hiding filter boxes
  const [showFilters, setShowFilters] = useState(false);

  // State for column sizes and resizing
  const [columns, _] = useState<typeof defaultColumns>(() => [
    ...defaultColumns,
  ]);

  // Also need state for the global text filter and column-specific filters
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState<string>("");

  // Also need shallow pagination (semi-controlled pagination state)
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const table = useReactTable({
    data,
    filterFns: {
      fuzzy: fuzzyFilter,
      caseInsensitive: filterFns.includesString,
      caseSensitive: filterFns.includesStringSensitive,
    },
    state: {
      columnFilters,
      globalFilter,
      pagination,
    },
    initialState: {
      columnVisibility: initialColumnVisibility,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    globalFilterFn: fuzzyFilter,
    columns,
    columnResizeMode: "onChange",
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    defaultColumn: {
      minSize: 25,
    },
  });

  // By default, sort by the default sort column specified by the user.
  // Otherwise, display rows in order given by server.
  useEffect(() => {
    let columnToSortBy: string | undefined;
    if (defaultSortColumn) {
      columnToSortBy = defaultSortColumn;
    }

    if (columnToSortBy && table.getState().sorting[0]?.id !== columnToSortBy) {
      table.setSorting([{ id: columnToSortBy, desc: false }]);
    }
  }, [table.getState().columnFilters[0]?.id]);

  return (
    <>
      <div className="flex flex-wrap items-center mt-2 gap-x-4">
        <div
          className={cn(
            "ml-6 py-2 text-sm italic font-medium text-slate-500 dark:text-slate-400 ",
            showToolbox ? "pb-0" : ""
          )}
        >
          Showing {table.getFilteredRowModel().rows.length} record
          {pluralizeByLength(table.getFilteredRowModel().rows)}
        </div>
        {!showToolbox ? (
          <PaginationControls
            table={table}
            expanded={false}
            pagination={pagination}
            setPagination={setPagination}
          />
        ) : null}
        <div className="grow"></div>
        {showToolbox ? null : (
          <GenericButton
            className="p-1 px-2 font-medium h-7 link"
            icon={<FontAwesomeIcon icon={faWrench} className="w-4 h-4" />}
            onClick={() => setShowToolbox(true)}
          >
            More table tools
          </GenericButton>
        )}
      </div>
      <div
        className={cn(
          "relative mb-1 sm:rounded-lg mt-3 border dark:border-2 border-slate-300 dark:border-slate-500 shadow-md shadow-gray-400/50 dark:shadow-gray-800/50 rounded-xl",
          showToolbox ? "" : "overflow-x-auto overflow-y-clip"
        )}
      >
        <TableToolbox
          {...{
            globalFilter,
            setGlobalFilter,
            showToolbox,
            table,
            setShowToolbox,
            showFilters,
            setShowFilters,
            pagination,
            setPagination,
          }}
        />
        <table
          className={cn(
            "w-full table-auto text-sm text-left text-slate-500 rtl:text-right dark:text-slate-400",
            tableClassName
          )}
          {...{ style: { width: table.getCenterTotalSize() } }}
        >
          <thead className="text-xs uppercase text-slate-700 bg-slate-100 dark:bg-slate-700 dark:text-slate-400">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    scope="col"
                    className="px-6 py-3"
                    {...{
                      colSpan: header.colSpan,
                      style: {
                        width:
                          // Try to allow Tailwind's table-auto to take effect
                          // unless user asks for a specific column width by
                          // dragging the resize handles. Thanks to
                          // https://github.com/TanStack/table/discussions/4179
                          header.getSize() !== 150
                            ? header.getSize()
                            : undefined,
                      },
                    }}
                  >
                    <div
                      className={cn(
                        "flex justify-between items-center h-full",
                        header.column.getCanSort()
                          ? "cursor-pointer select-none"
                          : ""
                      )}
                    >
                      <div
                        className={cn(
                          "flex flex-col h-full",
                          showFilters && header.column.getCanFilter()
                            ? "gap-y-2"
                            : ""
                        )}
                      >
                        {" "}
                        <div
                          className="flex items-center gap-x-2"
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          {" "}
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                          {/* Also render the ascending/descending symbol/unsorted */}
                          {header.column.getCanSort() ? (
                            <div className="mt-1 ml-2">
                              {{
                                asc: (
                                  <FontAwesomeIcon
                                    icon={faSortUp}
                                    className="w-4 h-4 text-slate-400 dark:text-slate-400"
                                  />
                                ),
                                desc: (
                                  <FontAwesomeIcon
                                    icon={faSortDown}
                                    className="w-4 h-4 text-slate-400 dark:text-slate-400"
                                  />
                                ),
                              }[header.column.getIsSorted() as string] ?? (
                                <FontAwesomeIcon
                                  icon={faSort}
                                  className="w-4 h-4 text-slate-400 dark:text-slate-400"
                                />
                              )}
                            </div>
                          ) : null}
                        </div>
                        <div className="grow"></div>
                        {header.column.getCanFilter() && showFilters ? (
                          <div className="">
                            <Filter column={header.column} table={table} />
                          </div>
                        ) : null}
                      </div>
                      {/* Now have the draggable column separator for resizing columns, if filter fields aren't shown. For some reason things become unresponsive if filter fields are shown. */}
                      {!showFilters ? (
                        <div
                          {...{
                            onMouseDown: header.getResizeHandler(),
                            onTouchStart: header.getResizeHandler(),
                            className: cn(
                              "transition rounded-lg px-1 py-2",
                              header.index <
                                header.headerGroup.headers.length - 1
                                ? "translate-x-8"
                                : "translate-x-3",
                              header.column.getIsResizing()
                                ? "bg-blue-400 dark:bg-blue-400 transition-none cursor-grabbing"
                                : "hover:bg-blue-200 dark:hover:bg-blue-700 hover:cursor-col-resize",
                              header.column.getCanSort()
                                ? "cursor-pointer select-none"
                                : ""
                            ),
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faGripLinesVertical}
                            className="w-3 h-3 text-slate-400 dark:text-slate-400"
                          />
                        </div>
                      ) : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                className="transition-colors ease-in-out border-b odd:bg-white odd:dark:bg-slate-800 even:bg-slate-50 even:dark:bg-slate-700 dark:border-slate-700 hover:bg-blue-100 dark:hover:bg-blue-900/50"
              >
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className="px-6 py-4">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {/* Show another copy of the table tools, but only if there's at least one visible row in the table. */}
        {table.getFilteredRowModel().rows.length > 1 ? (
          <TableToolbox
            {...{
              globalFilter,
              setGlobalFilter,
              showToolbox,
              table,
              setShowToolbox,
              showFilters,
              setShowFilters,
              pagination,
              setPagination,
            }}
          />
        ) : null}
        <div className="px-4 py-2"></div>
      </div>
    </>
  );
}

// Filter field(s) component for each column
function Filter({
  column,
  table,
}: {
  column: Column<any, unknown>;
  table: Table<any>;
}) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  const sortedUniqueValues = useMemo(
    () =>
      typeof firstValue === "number"
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues()]
  );

  return typeof firstValue === "number" ? (
    <div>
      <div className="flex space-x-2">
        <TanstackDebouncedInput
          type="number"
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
          value={(columnFilterValue as [number, number])?.[0] ?? ""}
          onChange={(value) =>
            column.setFilterValue((old: [number, number]) => [value, old?.[1]])
          }
          placeholder={`Min ${
            column.getFacetedMinMaxValues()?.[0]
              ? `(${column.getFacetedMinMaxValues()?.[0]})`
              : ""
          }`}
          className="w-16 p-1 font-normal rounded shadow order placeholder:text-xs"
        />
        <TanstackDebouncedInput
          type="number"
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
          value={(columnFilterValue as [number, number])?.[1] ?? ""}
          onChange={(value) =>
            column.setFilterValue((old: [number, number]) => [old?.[0], value])
          }
          placeholder={`Max ${
            column.getFacetedMinMaxValues()?.[1]
              ? `(${column.getFacetedMinMaxValues()?.[1]})`
              : ""
          }`}
          className="w-16 p-1 font-normal rounded shadow order placeholder:text-xs"
        />
      </div>
      <div className="h-1" />
    </div>
  ) : (
    <>
      <datalist id={column.id + "list"}>
        {sortedUniqueValues.slice(0, 5000).map((value: any) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <TanstackDebouncedInput
        type="text"
        value={(columnFilterValue ?? "") as string}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
        className="p-1 mb-0 font-normal border rounded shadow placeholder:text-xs"
        list={column.id + "list"}
      />
      <div className="h-1" />
    </>
  );
}
