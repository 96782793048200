// Client-side routes
import { lazy, Suspense } from "react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Layout from "./components/layout";
import LoadingSpinnerInline from "./components/spinners";
import useToken from "./lib/auth";
import SingleCharDatafilesOutlet from "./pages/experiments/characterization-results/characterization-results-detail-files-list";
import ExperimentDetailDatafilesDetailOutlet from "./pages/experiments/datafiles/datafiles-detail";
import ExperimentDetailDatafilesFormOutlet from "./pages/experiments/datafiles/datafiles-form";
import { OverviewOutletView } from "./pages/experiments/experiment-detail-overview";
import {
  GroupDetailPinnedPublicationsOutlet,
  GroupDetailRelatedPublicationsOutlet,
} from "./pages/groups/groups-detail-publications";

const CharacterizationResultFormOutlet = lazy(
  () =>
    import(
      "./pages/experiments/characterization-results/characterization-results-form"
    )
);
const CharacterizationFormKeyValPairFormOutlet = lazy(
  () =>
    import(
      "./pages/experiments/characterization-results/keyval-pairs/keyval-pairs-form"
    )
);
const ExperimentDetailDatafilesListOutlet = lazy(
  () => import("./pages/experiments/datafiles/datafiles-list")
);

const KeyValPairOverviewOutlet = lazy(
  () =>
    import(
      "./pages/experiments/characterization-results/keyval-pairs/keyval-pairs-list"
    )
);
const SinglePlotEditOutlet = lazy(
  () =>
    import(
      "./pages/experiments/characterization-results/scatter-plots/scatter-plots-edit-outlet"
    )
);
const ExperimentBasicMetadataFormPage = lazy(
  () => import("./pages/experiments/experiment-form")
);
const PublicationDetailPage = lazy(
  () => import("./pages/publications/publication-detail")
);
const PublicationFormPage = lazy(
  () => import("./pages/publications/publication-form")
);

const HomePage = lazy(() => import("./pages/home"));
const SimpleExperimentDumpsPage = lazy(
  () => import("./pages/simple_experiment_dump_list")
);
const SimpleExperimentDumpDetail = lazy(
  () => import("./pages/simple_experiment_dump_detail")
);
const UserListPage = lazy(() => import("./pages/user_list"));
const UserDetailPage = lazy(() => import("./pages/user_detail"));
const UserFormPage = lazy(() => import("./pages/user_form"));
const LoginPage = lazy(
  () => import("./pages/login-auth-registration/login_form")
);
const ExperimentListPage = lazy(
  () => import("./pages/experiments/experiment-list")
);
const ProjectListPage = lazy(() => import("./pages/projects/project-list"));
const GroupListPage = lazy(() => import("./pages/groups/groups-list"));
const PublicationListPage = lazy(
  () => import("./pages/publications/publication-list")
);
const ExperimentalDetailPageNew = lazy(
  () => import("./pages/experiments/experiment-detail")
);
const CharOverviewOutlet = lazy(
  () =>
    import(
      "./pages/experiments/characterization-results/characterization-results-list"
    )
);
const ProcedureOverviewOutlet = lazy(
  () => import("./pages/experiments/procedures/procedures-list")
);
const SingleProcedureDetailOutletView = lazy(
  () => import("./pages/experiments/procedures/procedures-detail")
);
const SingleProcedureEditOutletView = lazy(
  () => import("./pages/experiments/procedures/procedures-form")
);
const SingleProcedureStepDetailOutletView = lazy(
  () => import("./pages/experiments/procedures/procedure-step-detail")
);
const RegistrationPage = lazy(
  () => import("./pages/login-auth-registration/registration")
);
const VerifyEmailPage = lazy(
  () => import("./pages/login-auth-registration/verify-email")
);
const PasswordResetConfirmPage = lazy(
  () => import("./pages/login-auth-registration/password-reset-confirm")
);
const PasswordResetRequestPage = lazy(
  () => import("./pages/login-auth-registration/password-reset-request")
);
const GroupDetailPage = lazy(() => import("./pages/groups/groups-detail"));
const GroupDetailOverviewOutlet = lazy(
  () => import("./pages/groups/groups-detail-overview")
);
const GroupDetailMembershipOutlet = lazy(
  () => import("./pages/groups/groups-detail-membership")
);
const GroupDetailAffiliationsOutlet = lazy(
  () => import("./pages/groups/groups-detail-affiliations")
);
const InstitutionListPage = lazy(
  () => import("./pages/institutions/institution-list")
);
const RequestNewVerificationEmailMsgPage = lazy(
  () => import("./pages/login-auth-registration/request-new-verification-email")
);
const InstitutionDetailPage = lazy(
  () => import("./pages/institutions/institution-detail")
);
const InstitutionFormPage = lazy(
  () => import("./pages/institutions/institution-form")
);
const GroupBasicInfoForm = lazy(
  () => import("./pages/groups/groups-form-basic-metadata")
);
const ProjectDetailPage = lazy(() => import("./pages/projects/project-detail"));
const ProjectDetailOverviewOutlet = lazy(
  () => import("./pages/projects/project-detail-overview")
);
const ProjectDetailMembershipOutlet = lazy(
  () => import("./pages/projects/project-detail-members")
);
const ProjectDetailExperimentsOutlet = lazy(
  () => import("./pages/projects/project-detail-experiments")
);
const ProjectDetailPublicationsOutlet = lazy(
  () => import("./pages/projects/project-detail-publications")
);
const ProjectFormPage = lazy(() => import("./pages/projects/project-form"));
const SingleCharOverviewOutlet = lazy(
  () =>
    import(
      "./pages/experiments/characterization-results/characterization-results-detail-overview"
    )
);
const PlotOverviewOutlet = lazy(
  () =>
    import(
      "./pages/experiments/characterization-results/scatter-plots/scatter-plots-list"
    )
);
const SinglePlotOverviewOutlet = lazy(
  () =>
    import(
      "./pages/experiments/characterization-results/scatter-plots/scatter-plots-detail"
    )
);

// Set up Route wrapper for authenticated routes
// Thanks to https://dev.to/iamandrewluca/private-route-in-react-router-v6-lg5
// and https://stackoverflow.com/a/69869761.
const AuthRoute = ({ routeType }: { routeType: string }) => {
  const { token } = useToken();

  const isAuthUser = typeof token === "string" && token.length > 5;

  // If redirecting to another page after login, store that route now.
  const location = useLocation();
  if (!isAuthUser) {
    console.log(location);
    sessionStorage.setItem("nextLocation", JSON.stringify(location));
  }

  if (!isAuthUser && routeType === "private") {
    console.log("[AuthRoute] Going to private route.");
    return <Navigate to="/login" />;
  } else if (isAuthUser && routeType === "publicOnly") {
    console.log("[AuthRoute] Going to public-only route.");
    return <Navigate to="/home" />;
  }
  return <Outlet />;
};

export const AppRouter = () => (
  <BrowserRouter basename={import.meta.env.PUBLIC_URL}>
    <Suspense
      fallback={
        // Be sure never to lazy-load the Layout and LoadingSpinnerInline
        // components, as these are needed in case other things fail to
        // lazy-load!
        <Layout>
          <div className="flex justify-center">
            <LoadingSpinnerInline />
          </div>
        </Layout>
      }
    >
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="home" element={<HomePage />} />
        <Route path="users" element={<AuthRoute routeType="private" />}>
          <Route path="" element={<UserListPage />} />
          <Route path=":id" element={<UserDetailPage />} />
          <Route path=":id/edit" element={<UserFormPage />} />
        </Route>

        {/* Routes for projects */}
        <Route path="projects" element={<AuthRoute routeType="private" />}>
          <Route path="" element={<ProjectListPage />} />
          <Route path="new" element={<ProjectFormPage mode="create" />} />
          <Route path=":id/edit" element={<ProjectFormPage mode="update" />} />
          <Route path=":id" element={<ProjectDetailPage />}>
            <Route path="overview" element={<ProjectDetailOverviewOutlet />} />
            <Route
              path="membership"
              element={<ProjectDetailMembershipOutlet />}
            />
            <Route
              path="experiments"
              element={<ProjectDetailExperimentsOutlet />}
            />
            <Route
              path="publications"
              element={<ProjectDetailPublicationsOutlet />}
            />
          </Route>
        </Route>

        {/* Routes for publications */}
        <Route path="publications" element={<AuthRoute routeType="private" />}>
          <Route path="" element={<PublicationListPage />} />
          <Route path=":id" element={<PublicationDetailPage />} />
          <Route
            path=":id/edit"
            element={<PublicationFormPage mode="update" />}
          />
          <Route path="new" element={<PublicationFormPage mode="create" />} />
        </Route>
        <Route path="institutions" element={<AuthRoute routeType="private" />}>
          <Route path="" element={<InstitutionListPage />} />
          <Route path=":id" element={<InstitutionDetailPage />} />
          <Route
            path=":id/edit"
            element={<InstitutionFormPage mode="update" />}
          />
          <Route path="new" element={<InstitutionFormPage mode="create" />} />
        </Route>
        <Route
          path="simple_experiment_dumps"
          element={<AuthRoute routeType="private" />}
        >
          <Route path="" element={<SimpleExperimentDumpsPage />} />
        </Route>
        <Route
          path="simple_experiment_dump_detail"
          element={<AuthRoute routeType="private" />}
        >
          <Route path=":id" element={<SimpleExperimentDumpDetail />} />
        </Route>

        {/* Authentication, registration, and password reset pages */}
        <Route path="login" element={<LoginPage />}>
          <Route element={<LoginPage />} />
        </Route>
        <Route path="registration" element={<RegistrationPage />} />
        <Route
          path="registration/verify-email/:key/"
          element={<VerifyEmailPage />}
        />
        <Route
          path="registration/verify-email-request/"
          element={<RequestNewVerificationEmailMsgPage />}
        />
        <Route
          path="registration/password-reset/request/"
          element={<PasswordResetRequestPage />}
        />
        <Route
          path="registration/password-reset/confirm/:uid/:token/"
          element={<PasswordResetConfirmPage />}
        />

        {/* Pages to create, update, and view user groups */}
        <Route path="groups" element={<AuthRoute routeType="private" />}>
          <Route path="" element={<GroupListPage />} />
          <Route path=":id" element={<GroupDetailPage />}>
            <Route path="overview" element={<GroupDetailOverviewOutlet />} />
            <Route
              path="membership"
              element={<GroupDetailMembershipOutlet />}
            />
            <Route
              path="pinned-publications"
              element={<GroupDetailPinnedPublicationsOutlet />}
            />
            <Route
              path="related-publications"
              element={<GroupDetailRelatedPublicationsOutlet />}
            />
            <Route
              path="affiliations"
              element={<GroupDetailAffiliationsOutlet />}
            />
          </Route>
          <Route
            path=":id/edit"
            element={<GroupBasicInfoForm mode="update" />}
          />
          <Route path="new" element={<GroupBasicInfoForm mode="create" />} />
        </Route>

        {/* Main experiment pages */}
        <Route path="experiments" element={<AuthRoute routeType="private" />}>
          <Route path="" element={<ExperimentListPage />} />
          <Route
            path="new"
            element={<ExperimentBasicMetadataFormPage mode="create" />}
          />
          <Route
            path=":id/edit"
            element={<ExperimentBasicMetadataFormPage mode="update" />}
          />
          <Route path=":id" element={<ExperimentalDetailPageNew />}>
            <Route path="overview" element={<OverviewOutletView />} />

            {/* Procedure graph routes */}
            <Route
              path="procedures/overview"
              element={<ProcedureOverviewOutlet />}
            />
            <Route path="procedures/:procId">
              <Route
                path=""
                element={<SingleProcedureDetailOutletView />}
              ></Route>
            </Route>
            <Route
              path="procedures/:procId/edit"
              element={<SingleProcedureEditOutletView mode="update" />}
            />
            <Route
              path="procedures/new"
              element={<SingleProcedureEditOutletView mode="create" />}
            />

            {/* Datafile page routes */}
            <Route
              path="datafiles"
              element={<ExperimentDetailDatafilesListOutlet />}
            />
            <Route
              path="datafiles/:datafileId"
              element={<ExperimentDetailDatafilesDetailOutlet />}
            />
            <Route
              path="datafiles/:datafileId/edit"
              element={<ExperimentDetailDatafilesFormOutlet />}
            />

            {/* Characterization results routes */}
            <Route
              path="characterizations/overview"
              element={<CharOverviewOutlet />}
            />
            <Route
              path="characterizations/:charId/overview"
              element={<SingleCharOverviewOutlet />}
            />
            <Route
              path="characterizations/:charId/edit"
              element={<CharacterizationResultFormOutlet mode="update" />}
            />
            <Route
              path="characterizations/new"
              element={<CharacterizationResultFormOutlet mode="create" />}
            />

            {/* Keyval pairs (Custom Properties) routes */}
            <Route
              path="characterizations/:charId/keyval-pairs"
              element={<KeyValPairOverviewOutlet />}
            />
            <Route
              path="characterizations/:charId/keyval-pairs/edit"
              element={<CharacterizationFormKeyValPairFormOutlet />}
            />

            {/* Characterization-result-specific DataFile routes */}
            <Route
              path="characterizations/:charId/datafiles"
              element={<SingleCharDatafilesOutlet />}
            />

            {/* Scatter plot routes */}
            <Route
              path="characterizations/:charId/plots"
              element={<PlotOverviewOutlet />}
            />
            <Route
              path="characterizations/:charId/plots/:plotId/"
              element={<SinglePlotOverviewOutlet />}
            />
            <Route
              path="characterizations/:charId/plots/:plotId/edit"
              element={<SinglePlotEditOutlet mode="update" />}
            />
            <Route
              path="characterizations/:charId/plots/new"
              element={<SinglePlotEditOutlet mode="create" />}
            />
          </Route>
        </Route>
      </Routes>
    </Suspense>
  </BrowserRouter>
);
