// Table of publications
import { FC, HTMLAttributes, forwardRef } from "react";
import { cn } from "../../lib/text-utils";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { CellContext, createColumnHelper } from "@tanstack/react-table";
import { MyDateFormat } from "../../lib/date-utils";
import TanstackTableFilterableSortable from "../tables/tanstack-table-wrappers";
import { SimpleConfirmActionButton } from "../tables/row-edit-buttons";

export interface IPublicationWithFullAuthors extends IPublication {
  authors: IUser[];
}

export interface IPublicationsTable extends HTMLAttributes<HTMLDivElement> {
  publications: IPublicationWithFullAuthors[];
  maxAuthorsShown?: number;
  isGroupAdmin?: boolean;
  removePubHandler?: (
    info: CellContext<IPublicationWithFullAuthors, unknown>
  ) => void | Promise<void>;
}

// Have Tanstack table for publications
export const PublicationsTable: FC<IPublicationsTable> = ({
  publications,
  maxAuthorsShown = 5,
  className,
  isGroupAdmin = false,
  removePubHandler = undefined,
}) => {
  const columnHelper = createColumnHelper<IPublicationWithFullAuthors>();

  const columns = [
    columnHelper.accessor("title", {
      header: () => "Title",
      cell: (info) => (
        <Link
          to={`/publications/${info.row.original.id}/`}
          className="font-semibold link"
        >
          {info.getValue()}
        </Link>
      ),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor("date", {
      header: () => "Date",
      cell: (info) => <MyDateFormat theDate={info.getValue()} />,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor(
      // Want to generate string of all authors for filtering purposes, even
      // though we will render it differently.
      (row) => {
        const author_str = row.authors
          .map((author) => `${author.first_name} ${author.last_name}`)
          .join(", ");

        const additional_author_str = row.authors_unregistered
          .map((author) => `${author.given_name} ${author.family_name}`)
          .join(", ");

        return `${author_str}${
          additional_author_str ? ", " + additional_author_str : ""
        }`;
      },
      {
        id: "authors",
        cell: (info) => {
          // Want to have links to the registered authors in our list, and just
          // a list of names for everyone else.
          var author_components_list: JSX.Element[] = [];
          // Push onto array clickable links for registered authors...
          info.row.original.authors.forEach((author, idx) => {
            author_components_list.push(
              <Link
                key={`registered-author-${idx}-${info.row.original.id}-${author.id}`}
                to={`/users/${author.id}/`}
                className="font-medium link"
              >
                {author.first_name} {author.last_name}
              </Link>
            );
            if (idx < info.row.original.authors.length - 1) {
              author_components_list.push(<span>, </span>);
            }
          });

          // ...and a list of names for everyone else
          if (
            // Need another separating comma if we have both author lists
            // present.
            info.row.original.authors_unregistered.length > 0 &&
            info.row.original.authors.length > 0
          ) {
            author_components_list.push(
              <span key="author-extraauthor-sep-span">, </span>
            );
          }
          info.row.original.authors_unregistered.forEach((author, idx) => {
            author_components_list.push(
              <span key={`${idx}-extraauthor-span`}>
                {author.given_name} {author.family_name}
              </span>
            );
            if (idx < info.row.original.authors_unregistered.length - 1) {
              author_components_list.push(
                <span key={`${idx}-extraauthor-span-sep`}>, </span>
              );
            }
          });
          return (
            <>{author_components_list.slice(0, maxAuthorsShown * 2 - 1)}</>
          );
        },
      }
    ),
    columnHelper.accessor("doi", {
      header: () => "DOI",
      cell: (info) => (
        <a
          href={`https://dx.doi.org/${info.row.original.doi}`}
          className="font-medium link"
          target="_blank"
          rel="noopener noreferrer"
        >
          {info.row.original.doi}
        </a>
      ),
      footer: (info) => info.column.id,
    }),
    columnHelper.display({
      id: "url",
      header: () => "External Link",
      cell: (info) =>
        info.row.original.url ? (
          <a
            href={
              info.row.original.url
                ? info.row.original.url
                : `https://dx.doi.org/${info.row.original.doi}`
            }
            target="_blank"
            rel="noreferrer nofollow noopener"
            className="link"
          >
            <FontAwesomeIcon
              className="w-4 h-4"
              icon={faArrowUpRightFromSquare}
            />
          </a>
        ) : null,
    }),
    columnHelper.display({
      id: "delete_btns",
      header: () => "Actions",
      cell: (info) => (
        <div className="flex justify-center">
          <SimpleConfirmActionButton
            className="w-24"
            label="Delete"
            onClick={async () =>
              removePubHandler
                ? removePubHandler(info)
                : console.log("No remove-publication handler")
            }
          />
        </div>
      ),
      footer: (info) => info.column.id,
      size: 30,
    }),
  ];

  return (
    <TanstackTableFilterableSortable
      columns={columns}
      data={publications}
      showToolbox={false}
      tableClassName={className}
      defaultSortColumn="title"
      initialColumnVisibility={{
        delete_btns: isGroupAdmin,
      }}
    />
  );
};
