// Just copy the debounced input field component from their docs since I can't
// get it right myself.

import { useEffect, useState } from "react";
import { cn } from "../../lib/text-utils";

export function TanstackDebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  className = "",
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <input
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      className={cn(
        "w-full px-3 py-2 text-sm rounded-lg focus:ring-blue-400 focus:ring-2 shadow-sm shadow-gray-300 dark:shadow-gray-700 border border-gray-300 dark:border-gray-700 hover:border-gray-400 dark:hover:border-gray-600 hover:shadow-md transition dark:bg-slate-500 dark:text-slate-100 dark:placeholder:text-slate-200 outline-none placeholder:italic",
        className
      )}
    />
  );
}
