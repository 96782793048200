// Create a dropzone component for uploading files

import {
  faBroom,
  faCheck,
  faSave,
  faXmark,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { Accept, useDropzone } from "react-dropzone";
import { TFetchGeneralError } from "../lib/fetcher";
import { cn, formatBytes } from "../lib/text-utils";
import { GenericButton } from "./buttons";
import {
  MascApiFetchErrorsTypeset,
  SimpleToastControlled,
} from "./message-indicators";
import LoadingSpinnerInline, { LoadingSpinnerBtn } from "./spinners";

export interface IFileToUpload {
  file: File;
  status: "ready" | "progress" | "success" | "failure";
}

export interface IMyDropzone {
  setShowDropzone?: Dispatch<SetStateAction<boolean>>;
  handleSingleFileUpload: ({
    fileToUpload,
  }: {
    fileToUpload: IFileToUpload;
  }) => Promise<void | unknown>;
  maxIndividualFileSize: number;
  maxAllowableTotalFileSize: number;
}

// Accepted file files. Accept most of the file types that
// @cyntler/react-doc-viewer can preview.
export const acceptedFileTypes: Accept = {
  "image/bmp": [".bmp"],
  "text/csv": [".csv"],
  "application/vnd.oasis.opendocument.text": [".odt"],
  "application/msword": [".doc"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx",
  ],
  "image/gif": [".gif"],
  "image/jpg": [".jpg"],
  "image/jpeg": [".jpeg"],
  "application/pdf": [".pdf"],
  "image/png": [".png"],
  "application/vnd.ms-powerpoint": [".ppt"],
  "application/vnd.oasis.opendocument.presentation": [".odp"],
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": [
    ".pptx",
  ],
  "image/tiff": [".tiff"],
  "text/plain": [".txt", ".log", ".dat"],
  "application/vnd.ms-excel": [".xls"],
  "application/vnd.oasis.opendocument.spreadsheet": [".ods"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx",
  ],
  "video/mp4": [".mp4"],
};

// Error transformer used later to collate multiple file uploads' errors into a
// single error object.
const errTransformerShowNonFieldErrorsList = (
  err: TFetchGeneralError<IDataFile>
) =>
  err.non_field_errors
    ? err.non_field_errors.map((msg, idx) => (
        <ul key={idx} className="ml-4 list-disc">
          <li>{msg}</li>
        </ul>
      ))
    : [];

const MyDropzone: FC<IMyDropzone> = ({
  setShowDropzone,
  handleSingleFileUpload,
  maxIndividualFileSize,
}) => {
  // Have state for the files that will be uploaded
  const [filesToUpload, setFilesToUpload] = useState<IFileToUpload[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Have state for indicators
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState<string | JSX.Element>("");

  const acceptedFileExtensions = Object.entries(acceptedFileTypes)
    .map(([_, extensions]) => extensions.join(", "))
    .join(", ");

  const {
    getInputProps,
    getRootProps,
    isDragAccept,
    isDragActive,
    isDragReject,
    isFocused,
  } = useDropzone({
    accept: acceptedFileTypes,
    onDropAccepted(files: File[], _) {
      const newfiles: IFileToUpload[] = files.map((fileInstance, _) => ({
        file: fileInstance,
        status: "ready",
      }));

      setFilesToUpload([...filesToUpload, ...newfiles]);
    },
  });

  const fileList = filesToUpload.map((file, idx) => (
    <li key={idx}>
      {file.file.name} - {formatBytes(file.file.size)}
      {file.status === "ready" ? (
        <span>
          <span>{" ("}</span>
          <span
            className="italic underline cursor-pointer link"
            onClick={() =>
              setFilesToUpload(
                filesToUpload.filter((_, newIdx) => newIdx !== idx)
              )
            }
          >
            remove
          </span>
          <span>{")"}</span>
        </span>
      ) : null}
      <div className="inline-block ml-4">
        {
          {
            ready: null,
            progress: <LoadingSpinnerInline svgClassName="h-4 w-4" />,
            success: (
              <FontAwesomeIcon
                icon={faCheck}
                className="w-4 h-4 text-green-500"
              />
            ),
            failure: (
              <FontAwesomeIcon
                icon={faXmark}
                className="w-4 h-4 text-red-500"
              />
            ),
          }[file.status]
        }
      </div>
    </li>
  ));

  return (
    <div className="container">
      <div className="flex justify-center">
        <SimpleToastControlled
          icon={<FontAwesomeIcon icon={faXmarkCircle} />}
          message={message}
          isVisible={showToast}
          setIsVisible={setShowToast}
          iconStroke="text-red-500"
          className="mt-4"
          iconBg="bg-red-100"
        />
      </div>
      <div
        {...getRootProps({
          className: cn(
            "my-3 mx-4 p-8 h-8 border border-4 rounded-lg bg-slate-50 dark:bg-slate-600 border-slate-300 dark:border-slate-500 border-dashed flex items-center justify-center",
            isDragActive ? "border-blue-400 dark:border-blue-200" : "",
            isDragAccept ? "border-green-400 dark:border-green-200" : "",
            isDragReject ? "border-red-400 dark:border-red-200" : "",
            isFocused ? "border-solid" : ""
          ),
        })}
      >
        <input {...getInputProps()} />
        <p>
          Drop files here, or click to select files from computer. (
          {acceptedFileExtensions} allowed)
        </p>
      </div>
      <div>
        <p>Files to upload:</p>
        <ul>{fileList}</ul>
        <div className="flex mt-4 gap-x-2">
          <GenericButton
            accentedButton
            icon={
              isSubmitting ? (
                <LoadingSpinnerBtn />
              ) : (
                <FontAwesomeIcon icon={faSave} />
              )
            }
            disabled={isSubmitting}
            onClick={async () => {
              // Like Formik, have a global form isSubmitting state which is
              // turned off once the async upload handler finishes.
              setIsSubmitting(true);
              setShowToast(false);

              // The upload handler the user specifies should do the work of
              // uploading a single file. This component will do the work of
              // running this handler once per file, collecting any errors that
              // might propagate from any of the uploads, and showing the user
              // a summary of any errors that result.

              let newState = structuredClone(filesToUpload);

              // Also have some way to store any errors that arise during the upload
              // process, for each file.
              let errors: TFetchGeneralError<IDataFile>[] = [];

              try {
                // Queue the upload operation for each file. We depend on the
                // user's upload handler to actually commit the POST/PUT request,
                // but we will take care of trapping errors and tracking the
                // overall upload progress as each file finishes uploading.
                let uploadOperations = filesToUpload.map(
                  async (fileToUpload, idx) => {
                    newState[idx].status = "progress";
                    setFilesToUpload([...newState]);

                    try {
                      // Await the user's single-file upload handler here.
                      await handleSingleFileUpload({ fileToUpload });

                      // ...end of user's single-file upload handler control.
                      newState[idx].status = "success";
                    } catch (err) {
                      // If error resulted, add it to the errors list, and we will handle it later.
                      if (err instanceof Error) {
                        newState[idx].status = "failure";
                        errors.push(err);
                      }
                    }

                    setFilesToUpload([...newState]);
                  }
                );

                await Promise.all(uploadOperations);

                // When done, always clear the queue of files to upload so user
                // doesn't click upload button a second time and accidentally
                // re-upload the file.
                setFilesToUpload([]);

                // If there were any errors for any of the files when attempting
                // to upload, collect the detail messages and merge them into a
                // single Error, which we will display to the user.
                if (errors.length > 0) {
                  console.log("We need a combined error object.");
                  let combinedErrors: TFetchGeneralError<IDataFile> = new Error(
                    "There were some problems uploading files."
                  );
                  combinedErrors.status = 403;
                  combinedErrors.non_field_errors = errors
                    .filter(
                      (err, _): err is TFetchGeneralError<IDataFile> =>
                        !!(err.info && err.info.detail)
                    )
                    .map((err, _) =>
                      err.info && err.info.detail ? err.info.detail : ""
                    );

                  console.log(
                    "Collected non-field errors:",
                    combinedErrors.non_field_errors
                  );

                  // Also add in any "file_instance" fields that may have arisen
                  combinedErrors.non_field_errors.push(
                    ...errors
                      .filter(
                        (err, _): err is TFetchGeneralError<IDataFile> =>
                          !!err.info?.file_instance
                      )
                      .map((err, _) =>
                        err.info && err.info.file_instance
                          ? err.info.file_instance[0]
                          : ""
                      )
                  );

                  // Also add in any too-large (413) errors.
                  if (
                    errors.filter(
                      (err, _): err is TFetchGeneralError<IDataFile> =>
                        // Filter on our non-standard client error code.
                        err.status === 452
                    ).length > 0
                  ) {
                    combinedErrors.non_field_errors.push(
                      `One or more of your files may be too large to upload. Ensure that each of the files you want to upload is ${formatBytes(
                        maxIndividualFileSize
                      )} or smaller.`
                    );
                  }

                  throw combinedErrors;
                }

                // If there were no errors, then close the dropzone.
                if (setShowDropzone) {
                  setShowDropzone(false);
                }
              } catch (err) {
                if (err instanceof Error) {
                  // If file upload fails (for instance, because it would cause
                  // experiment storage to exceed quota), we'd get just a
                  // generic error instead of one with IDataFile fields. So the
                  // error type has a blank type parameter.
                  let e: TFetchGeneralError<IDataFile> = err;
                  setMessage(
                    <MascApiFetchErrorsTypeset
                      error={e}
                      errorTransformer={errTransformerShowNonFieldErrorsList}
                    />
                  );
                  setShowToast(true);

                  // Also clear all files so that user can try again.
                  setFilesToUpload([]);
                }
              } finally {
                setIsSubmitting(false);
              }
            }}
          >
            Upload files
          </GenericButton>
          <GenericButton
            icon={<FontAwesomeIcon icon={faBroom} />}
            onClick={() => setFilesToUpload([])}
            disabled={isSubmitting}
          >
            Clear
          </GenericButton>
        </div>
      </div>
    </div>
  );
};

export default MyDropzone;
