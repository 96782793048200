import Navbar from "../components/navbar";
import { useState, useContext, useEffect } from "react";
import GlobalStateContext from "../lib/globalStateContext";
import useToken, { logoutUser } from "../lib/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { mutate } from "swr";

export default function Layout({ children, fullWidth = false }) {
  const globalStateData = useContext(GlobalStateContext);
  const { theme, setTheme } = globalStateData;
  // const [theme, setTheme] = useState(false);

  // Want to automatically login/logout the user on this tab if they have been
  // logged in/logged out on another tab
  // Thanks to https://stackoverflow.com/a/58541032
  const navigate = useNavigate();
  const location = useLocation();

  const handleStorageChange = (e) => {
    // Get new token directly from storage event
    if (e.key !== "token") {
      return;
    }
    const token = JSON.parse(e.newValue)?.token;

    if (typeof token === "string") {
      console.log("Logged in in another tab; refreshing state here.");
      mutate(`${import.meta.env.VITE_API_URL}/api/knox/user/`);

      const storedLocation =
        JSON.parse(sessionStorage.getItem("nextLocation"))?.pathname || 0;
      navigate(storedLocation);
    } else {
      // Assume user logged out. Need to navigate to login page.
      console.log("User logged out in another tab; going to home here.");
      mutate(`${import.meta.env.VITE_API_URL}/api/knox/user/`);
      // Set location so that login screen will return to current location
      sessionStorage.setItem("nextLocation", JSON.stringify(location));
      navigate("/login");
    }
  };

  useEffect(() => {
    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [logoutUser]);

  return (
    <div className={theme}>
      <div className="flex flex-col justify-start h-screen max-h-screen">
        <Navbar themeSetter={[theme, setTheme]} />
        <div
          className={`${
            !fullWidth ? "pt-8 overflow-y-scroll" : "pt-1"
          } flex-1 min-h-0 bg-slate-100 dark:bg-slate-700`}
        >
          <div
            className={`${
              !fullWidth
                ? "container px-2 mx-auto"
                : "flex flex-col h-full max-h-full"
            } text-black dark:text-slate-100`}
          >
            {/* <header className="text-3xl font-bold text-center">
              <p>DMREF Metal Alloy and Synthesis Conditions Database</p>
            </header> */}
            <div className="flex flex-col h-full max-h-full">
              <div className="flex-1 min-h-0 grow">{children}</div>
              {/* Spacer div */}
              <div
                className={`${
                  fullWidth ? "bg-slate-200 dark:bg-slate-600 py-4" : "py-8"
                } w-full text-xs text-center text-gray-500  dark:text-gray-300`}
              >
                Copyright 2023 by the Regents of the University of Michigan. All
                rights reserved.
              </div>
            </div>
            {/* <div className="border-2 border-green-300 grow">
              Other child
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
