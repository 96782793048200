// Define main components needed for the sidebar-detail UI

import {
  FC,
  ReactNode,
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Layout from "../layout";
import { MenuItem } from "./sidebar-menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import LoadingSpinnerInline from "../spinners";

export default function SidebarDetailUI({
  menuItems,
  header,
  children,
}: {
  menuItems: (ISingleMenuItemSpec | IMultiLevelMenuItemSpec)[];
  header: JSX.Element;
  children?: ReactNode;
}) {
  // Want to have a dragging-resizable sidebar
  // Thanks to https://codesandbox.io/s/react-resizable-sidebar-kz9de?file=/src/App.js
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [isResizing, setIsResizing] = useState(false);

  // Initial width of sidebar
  const [sidebarWidth, setSidebarWidth] = useState(280);

  const startResizing = useCallback(() => {
    setIsResizing(true);
  }, []);

  const stopResizing = useCallback(() => {
    setIsResizing(false);
  }, []);

  const resize = useCallback(
    (mouseMoveEvent: MouseEvent) => {
      if (isResizing && sidebarRef.current) {
        setSidebarWidth(
          mouseMoveEvent.clientX -
            sidebarRef.current.getBoundingClientRect().left
        );
      }
    },
    [isResizing]
  );

  const menuComponents = menuItems ? (
    menuItems.map((item, key) => <MenuItem key={key} item={item} />)
  ) : (
    <MenuItem
      item={{
        title: "Menu",
        icon: <FontAwesomeIcon icon={faBars} />,
        to: "#",
        items: [],
      }}
    />
  );

  useEffect(() => {
    window.addEventListener("mousemove", resize);
    window.addEventListener("mouseup", stopResizing);
    return () => {
      window.removeEventListener("mousemove", resize);
      window.removeEventListener("mouseup", stopResizing);
    };
  }, [resize, stopResizing]);

  return (
    <Layout fullWidth={true}>
      <div className="flex flex-col h-full">
        <div className="flex-none py-2 bg-slate-200 dark:bg-slate-600">
          {header}
        </div>
        <div className="flex flex-1 min-h-0">
          <div
            className="flex-none min-w-[200px] max-w-[480px] overflow-y-auto"
            ref={sidebarRef}
            style={{ width: sidebarWidth }}
            onMouseDown={(e) => e.preventDefault()}
          >
            {menuComponents}
          </div>
          <div
            className="flex-none w-1 transition bg-slate-200 dark:bg-slate-600 hover:bg-blue-400 dark:hover:bg-blue-300/50 cursor-col-resize"
            onMouseDown={startResizing}
          ></div>
          <div className="overflow-y-auto grow">
            <div className="h-full px-4 py-4">
              {/* Allow outlet components to lazy load without the page flickering */}
              <Suspense fallback={<LoadingSpinnerInline />}>
                {children}
              </Suspense>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
