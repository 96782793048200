import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PlusIcon } from "@heroicons/react/24/outline";
import { FC, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { GenericButton } from "../../components/buttons";
import CharacterizationCard from "../../components/characterizations/tablets";
import { ChemicalFormulaMathJax } from "../../components/chem-formula-display";
import { CreatedModifiedAtByIndicator } from "../../components/message-indicators";
import {
  OverviewCard,
  UserTileSmall,
} from "../../components/overview-page-components";
import LoadingSpinnerInline from "../../components/spinners";
import { SimpleControlledModalDialog } from "../../components/tables/row-edit-buttons";
import {
  useApiModelDetailView,
  useApiModelListView,
} from "../../lib/api-mutate-hooks";
import { useCurrentKnoxUser } from "../../lib/auth";
import { DateWithDistance } from "../../lib/date-utils";
import { pluralizeByLength } from "../../lib/text-utils";

export const ViewExperimentBasicMetadata: FC<{ experiment: IExperiment }> = ({
  experiment,
}) => {
  const navigate = useNavigate();

  // Modal state
  const [showDestroyExperimentModal, setShowDestroyExperimentModal] =
    useState(false);

  // Get currently logged-in user and the IDs of projects to which they belong.
  const { currentKnoxUser, currentKnoxUserError } = useCurrentKnoxUser({
    shouldGetData: true,
  });
  const { data: fullUser, error: fullUserError } = useApiModelDetailView<IUser>(
    {
      resourceName: "users",
      id: currentKnoxUser?.pk,
      shouldGetData: currentKnoxUser && !currentKnoxUserError,
    }
  );

  // Get delete handler for current experiment and list mutator
  const { destroySingleModelInstance } = useApiModelDetailView<IExperiment>({
    resourceName: "experiments",
    id: experiment.id,
    shouldGetData: false,
    mutateAfterChange: false,
  });

  const { mutate: listMutate } = useApiModelListView<IExperiment>({
    resourceName: "experiments",
    shouldGetData: false,
  });

  return (
    <>
      {currentKnoxUser ? (
        <SimpleControlledModalDialog
          isOpen={showDestroyExperimentModal}
          setIsOpen={setShowDestroyExperimentModal}
          title="Really delete this experiment?"
          actionLabel="Yes, delete it all"
          confirmHandler={async () => {
            await destroySingleModelInstance();
            await listMutate();
            navigate("/experiments");
          }}
        >
          <p>
            You'll lose all characterization data, procedures, and other data
            associated with this experiment.{" "}
          </p>
          <p className="mt-2 font-semibold">This can't be undone.</p>
        </SimpleControlledModalDialog>
      ) : null}
      <p>
        <span className="font-bold">Name: </span>
        {experiment.name}
      </p>
      <p>
        <span className="font-bold">Description: </span>
        <span className="italic text-slate-700 dark:text-slate-300">
          {experiment.description}
        </span>
      </p>
      <p>
        <span className="font-bold">Start Date: </span>
        <DateWithDistance theDate={experiment.start_date} />
      </p>
      <p>
        <span className="font-bold">End Date: </span>
        <DateWithDistance theDate={experiment.end_date} />
      </p>
      <p>
        <span className="font-bold">Associated publication: </span>
        {experiment.publication ? (
          <Link
            to={`/publications/${experiment.publication.id}`}
            className="text-blue-500 underline dark:text-blue-300"
          >
            {experiment.publication.title}
          </Link>
        ) : (
          "(no publication selected)"
        )}
      </p>
      <p>
        <span className="font-bold">Associated project: </span>
        {experiment.project ? (
          <Link
            to={`/projects/${experiment.project.id}/overview`}
            className="text-blue-500 underline dark:text-blue-300"
          >
            {experiment.project.name}
          </Link>
        ) : (
          "(no associated project)"
        )}
      </p>
      <p>
        <span className="font-bold">Target composition: </span>
        {experiment.target_composition &&
        experiment.target_composition.length > 0 ? (
          <ChemicalFormulaMathJax
            formulaJson={experiment.target_composition_json}
          />
        ) : (
          <span className="italic text-slate-600 dark:text-slate-400">
            (no target composition)
          </span>
        )}
      </p>
      <CreatedModifiedAtByIndicator object={experiment} className="mt-2" />
      {fullUser &&
      !fullUserError &&
      fullUser.my_projects?.includes(experiment.project_explicit_id) ? (
        <div className="flex items-center mt-3 gap-x-2">
          <Link to={`/experiments/${experiment.id}/edit`}>
            <GenericButton
              accentedButton
              icon={<FontAwesomeIcon icon={faPencil} />}
              className="h-8 px-2 py-0"
            >
              Edit
            </GenericButton>
          </Link>
          <GenericButton
            icon={<FontAwesomeIcon icon={faTrash} />}
            className="h-8 px-2 py-0 bg-red-400 dark:bg-red-600/50 "
            onClick={() => setShowDestroyExperimentModal(true)}
          >
            Delete
          </GenericButton>
        </div>
      ) : null}
    </>
  );
};

interface IExperimentSlim extends IExperiment {
  characterization_results: number[];
}

export const OverviewOutletView = () => {
  // Get experiment data

  // General strategy for parsing integer URL params. Thanks to
  // https://stackoverflow.com/a/71299745/11536255
  const params = useParams<{ id: string }>();
  const id = params.id ? parseInt(params.id) : undefined;

  // Get experiment data
  const { data: experiment, error: experimentError } =
    useApiModelDetailView<IExperimentSlim>({
      resourceName: "experiments",
      id,
      shouldGetData: true,
    });

  // Need dependent query to get project participants.
  const { data: people, error: peopleError } = useApiModelListView<
    Pick<
      IUser,
      "id" | "first_name" | "last_name" | "username" | "gravatar_hash"
    >,
    true
  >({
    resourceName: "users",
    shouldGetData: !!(
      experiment &&
      !experimentError &&
      experiment.project.users &&
      experiment.project.users.length > 0
    ),
    fields: ["id", "first_name", "last_name", "username", "gravatar_hash"],
    queryParams: {
      id__in: String(experiment?.project.users),
      page_size: "5",
      page: "1",
    },
  });

  // Dependent query to get characterization results with char result type and
  // scatter plot metadata.
  const { data: characterizationResults, error: characterizationResultsError } =
    useApiModelListView<ICharacterizationResultExtra>({
      resourceName: "characterization_results",
      expand: ["characterization_type", "scatter_plots"],
      shouldGetData: !!(
        experiment &&
        !experimentError &&
        experiment.characterization_results &&
        experiment.characterization_results.length > 0
      ),
      queryParams: {
        id__in: String(experiment?.characterization_results),
      },
    });

  return (
    <div className="container">
      {experiment && !experimentError ? (
        <div className="flex flex-col">
          <div className="flex items-stretch gap-x-4">
            <OverviewCard title="Basic metadata">
              <ViewExperimentBasicMetadata experiment={experiment} />
            </OverviewCard>
            <OverviewCard title="Project Info">
              <div>
                <span className="font-bold">Name:</span>{" "}
                <span>{experiment?.project.name}</span>
              </div>
              <div>
                <span className="font-bold">Description:</span>{" "}
                <span className="italic text-slate-700">
                  {experiment?.project.description}
                </span>
              </div>
              <div>
                <span className="font-bold">Funding:</span>{" "}
                <span>{experiment?.project.funding_sources}</span>
              </div>
            </OverviewCard>
            <OverviewCard title="Project Participants">
              <div className="grid items-stretch gap-4 xl:grid-cols-3 lg:grid-cols-1">
                {people && !peopleError
                  ? people.results.map((user, idx: number) => (
                      <UserTileSmall user={user} key={idx} />
                    ))
                  : null}
              </div>
            </OverviewCard>
          </div>
          <h2 className="py-2 mt-4 text-xl font-bold">
            <Link
              to={`/experiments/${experiment.id}/characterizations/overview`}
              className="font-semibold link"
            >
              {experiment.characterization_results.length} Characterization
              Result{pluralizeByLength(experiment.characterization_results)}
            </Link>
          </h2>
          <div className="flex gap-x-2">
            {characterizationResults && !characterizationResultsError
              ? characterizationResults.map(
                  (
                    characterization: ICharacterizationResultExtra,
                    idx: number
                  ) => (
                    <Link
                      to={`/experiments/${experiment.id}/characterizations/${characterization.id}/overview`}
                      key={idx}
                    >
                      <CharacterizationCard
                        key={characterization.id}
                        data={characterization}
                      />
                    </Link>
                  )
                )
              : null}
            <div className="flex flex-col items-center justify-center w-48 h-48 px-2 py-2 overflow-y-auto bg-white rounded-lg shadow-lg dark:bg-slate-600">
              <div className="flex items-center justify-center w-16 h-16 text-5xl font-semibold rounded-full bg-slate-300">
                <PlusIcon className="w-12 h-12 text-slate-700" />
              </div>
              <div className="mt-3 text-base font-semibold text-slate-700 dark:text-slate-300">
                New
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center">
          <LoadingSpinnerInline />
        </div>
      )}
    </div>
  );
};
