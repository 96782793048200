// Simple button-based components to perform an action on a row of a Tanstack table

import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import {
  Dispatch,
  FC,
  Fragment,
  HTMLAttributes,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { cn } from "../../lib/text-utils";
import { GenericButton, IGenericButton } from "../buttons";

type TConfirmActionButton = Omit<
  HTMLAttributes<HTMLButtonElement>,
  "onClick" | "className"
> &
  Omit<IGenericButton, "onClick" | "type">;
interface ISimpleConfirmActionButton extends TConfirmActionButton {
  label: string;
  onClick: (arg0?: any) => void | Promise<void>;
  confirmMsg?: string;
  confirmClassName?: string;
  timeoutMs?: number;
  className?: string;
}
export const SimpleConfirmActionButton: FC<ISimpleConfirmActionButton> = ({
  label,
  onClick,
  confirmMsg = "Confirm?",
  confirmClassName = "bg-red-400 dark:bg-red-600 group-hover:bg-red-500 dark:group-hover:bg-red-500 focus:ring-red-200 dark:focus:ring-red-400",
  timeoutMs = 2000,
  className,
  ...rest
}) => {
  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  const performAction = useCallback(async () => {
    if (showConfirm) {
      await onClick();
      setShowConfirm(false);
    } else {
      setShowConfirm(true);
    }
  }, [showConfirm]);

  // Also reset button state after specified amount of time
  useEffect(() => {
    let t = setTimeout(() => {
      // Be sure to check for truthiness/existence of the setTimeout interval
      // itself to avoid trying to update state after the component is
      // unmmounted. See https://stackoverflow.com/a/60907638.
      if (showConfirm && t) {
        setShowConfirm(false);
      }
    }, timeoutMs);
    return () => {
      clearTimeout(t);
    };
  }, [showConfirm]);

  return (
    <GenericButton
      type="button"
      {...rest}
      className={cn(
        "text-slate-800 dark:text-slate-200",
        showConfirm ? confirmClassName : "",
        className
      )}
      onClick={() => performAction()}
    >
      {showConfirm ? confirmMsg : label}
    </GenericButton>
  );
};

// Also want to have a modal dialog for particularly risky actions.
interface ISimpleModalDialog {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  title: string;
  description?: string | JSX.Element | JSX.Element[];
  children?: string | JSX.Element | JSX.Element[];
  actionLabel?: string;
  cancelLabel?: string;
  confirmHandler?: () => void | Promise<void>;
}
export const SimpleControlledModalDialog: FC<ISimpleModalDialog> = ({
  isOpen,
  setIsOpen,
  title,
  description = "",
  children,
  actionLabel = "Confirm",
  cancelLabel = "Cancel",
  confirmHandler = () => {},
}) => {
  const cancelButtonRef = useRef(null);

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setIsOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75 backdrop-blur-sm" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl dark:bg-slate-600 sm:my-8 sm:w-full sm:max-w-lg">
                <div className="px-4 pt-5 pb-4 bg-white dark:bg-slate-600 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full dark:bg-red-200 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon
                        className="w-6 h-6 text-red-600 dark:text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900 dark:text-slate-300"
                      >
                        {title}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500 dark:text-slate-400">
                          {description}
                        </p>
                        <div className="text-sm text-gray-500 dark:text-slate-400">
                          {children}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex px-4 py-3 bg-gray-50 dark:bg-slate-600 gap-x-2 sm:gap-y-2 sm:flex-row-reverse sm:px-6">
                  <GenericButton
                    accentedButton
                    onClick={() => {
                      setIsOpen(false);
                      confirmHandler();
                    }}
                  >
                    {actionLabel}
                  </GenericButton>
                  <GenericButton
                    ref={cancelButtonRef}
                    onClick={() => setIsOpen(false)}
                  >
                    {cancelLabel}
                  </GenericButton>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
