import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import { Link } from "react-router-dom";
import { cn } from "../../lib/text-utils";

interface ISidebarDetailHeader {
  backBtnLabel: string;
  backBtnIcon?: JSX.Element;
  title: string;
  leftBtnPadding?: boolean;
  backBtnTo: string;
  itemType: string;
  itemTypeTWStyle?: string;
}
export const SidebarDetailHeader: FC<ISidebarDetailHeader> = ({
  backBtnLabel,
  backBtnIcon = (
    <FontAwesomeIcon className="w-4 h-4 mr-1" icon={faChevronLeft} />
  ),
  title,
  leftBtnPadding = true,
  backBtnTo,
  itemType,
  itemTypeTWStyle = "text-cyan-800 border-slate-400 dark:text-cyan-200/70 dark:border-slate-500",
}) => {
  return (
    <div className="flex flex-wrap justify-between">
      <Link
        to={backBtnTo}
        className="flex items-center text-blue-600 dark:text-blue-300 hover:text-white dark:hover:text-black group"
      >
        <div
          className={cn(
            "flex items-center px-2 py-1 transition border border-blue-600 rounded-lg dark:border-blue-300 dark:group-hover:bg-blue-300 group-hover:bg-blue-600",
            leftBtnPadding ? "ml-4" : ""
          )}
        >
          {backBtnIcon}
          {backBtnLabel}
        </div>
      </Link>
      <div className="flex items-center gap-x-4">
        <span
          className={cn(
            "px-1 mt-[0.10rem] -ml-6 text-xs font-semibold uppercase border-2 rounded-lg border-slate-400",
            itemTypeTWStyle
          )}
        >
          {itemType}
        </span>
        <span className="text-2xl font-bold">{title}</span>
      </div>
      <div className="w-42"></div>
    </div>
  );
};
