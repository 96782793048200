import React, { useState, useEffect, Dispatch, SetStateAction } from "react";

import fetcher from "./lib/fetcher";
import { SWRConfig } from "swr";
import { AppRouter } from "./Router";
import { MathJaxContext } from "better-react-mathjax";
import GlobalStateContext from "./lib/globalStateContext";

import useToken from "./lib/auth";

// MathJax config
// Thanks to https://jsbin.com/?html,output and
// https://www.npmjs.com/package/better-react-mathjax
const config = {
  tex: {
    inlineMath: [["\\(", "\\)"]],
    packages: { "[+]": ["mhchem"] },
  },
  loader: { load: ["[tex]/mhchem"] },
};

const swrConfig = {
  fetcher: fetcher,
};

export type ThemeValues = "light" | "dark";
export type GlobalStateType = {
  theme: string;
  setTheme: Dispatch<SetStateAction<ThemeValues>>;
  token: string;
  setToken: Dispatch<string>;
};

function App() {
  // Instantiate state that would be important for the whole application.
  // If theme is not set in localstorage, set it to light.

  let initialTheme: ThemeValues;
  const currentTheme = window.localStorage.getItem("theme");

  function isTheme(arg: string | null): arg is ThemeValues {
    return ["light", "dark"].some((element) => element === arg);
  }

  if (isTheme(currentTheme)) {
    initialTheme = currentTheme;
  } else {
    initialTheme = "light";
  }

  // Add things to global in-memory app state
  const [theme, setTheme] = useState<ThemeValues>(initialTheme);
  const { token, setToken } = useToken();

  const globalStateData: GlobalStateType = { theme, setTheme, token, setToken };

  useEffect(() => {
    window.localStorage.setItem("theme", theme);
  }, [theme]);

  return (
    <React.StrictMode>
      <MathJaxContext config={config}>
        <GlobalStateContext.Provider value={globalStateData}>
          <SWRConfig value={swrConfig}>
            <AppRouter />
          </SWRConfig>
        </GlobalStateContext.Provider>
      </MathJaxContext>
    </React.StrictMode>
  );
}

export default App;
