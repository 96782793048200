// This form is for editing datafiles. We'll only really be uploading them from
// the Experiments or Characterization Results pages, so probably just need to
// cover the case where we're editing an existing record, not creating a new
// one.

import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Formik } from "formik";
import { useCallback, useContext, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { GenericButton, SubmitButton } from "../../../components/buttons";
import { acceptedFileTypes } from "../../../components/file-upload-dropzone";
import {
  MySingleFileInputField,
  MyTextAreaInput,
} from "../../../components/formik-form-components";
import {
  MascApiFetchErrorsTypeset,
  SimpleToastControlled,
} from "../../../components/message-indicators";
import LoadingSpinnerInline from "../../../components/spinners";
import { useApiModelDetailView } from "../../../lib/api-mutate-hooks";
import { useFullCurrentUser } from "../../../lib/auth";
import {
  TFetchGeneralError,
  fetcherMultipartFormdata,
} from "../../../lib/fetcher";
import { formatBytes } from "../../../lib/text-utils";
import { YupToTS } from "../../../types/exported-types";
import { ExperimentContext } from "../experiment-detail";

export default function ExperimentDetailDatafilesFormOutlet() {
  // Get datafile ID from URL
  const params = useParams<{ datafileId: string }>();
  const datafileId = params.datafileId
    ? parseInt(params.datafileId)
    : undefined;

  // Get the JSON metadata for this datafile
  const {
    data: dataFile,
    error: dataFileError,
    mutate: mutateSingleDataFile,
  } = useApiModelDetailView<IDataFile>({
    id: datafileId,
    resourceName: "datafiles",
    shouldGetData: !!datafileId,
  });

  // Also want the experiment context
  const { experiment, experiment_error, experiment_mutate } =
    useContext(ExperimentContext);

  // Also need full user info
  const { fullUser, fullUserError } = useFullCurrentUser({
    shouldGetData: true,
  });

  // Need a special ref to handle file field in an uncontrolled fashion. Thanks
  // to
  // https://dev.to/atosh502/file-uploads-and-validation-with-react-and-formik-2mbk.
  const fileRef = useRef<HTMLInputElement>(null);

  interface IDataFileMetadataEditable
    extends Pick<
      IDataFile,
      "id" | "description" | "file_instance" | "owning_experiment"
    > {}

  const initialValues: YupToTS<typeof validationSchema> = {
    file_instance: "",
    description: dataFile?.description,
  };

  // Have state for indicators
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState<string | JSX.Element>("");

  // Also have navigator
  const navigate = useNavigate();

  // Set up validation schema. Thanks to
  // https://dev.to/atosh502/file-uploads-and-validation-with-react-and-formik-2mbk
  let maxFileSizeBytes = experiment?.max_individual_file_bytes
    ? experiment.max_individual_file_bytes
    : 5 * 1024 * 1024;
  const validationSchema = Yup.object().shape({
    file_instance: Yup.mixed()
      .test(
        "is-file-too-big",
        `File exceeds ${formatBytes(maxFileSizeBytes)} limit`,
        () => {
          let valid = true;
          const files = fileRef?.current?.files;
          if (files) {
            const fileArr = Array.from(files);
            fileArr.forEach((file) => {
              if (file.size > maxFileSizeBytes) {
                valid = false;
              }
            });
          }
          return valid;
        }
      )
      .test("is-file-of-correct-type", "File is not of supported type", () => {
        let valid = true;
        if (
          fileRef?.current &&
          fileRef.current.files &&
          fileRef.current.files[0]
        ) {
          const singleFile = fileRef?.current?.files[0];
          const type = singleFile.type.split("/")[1];
          console.log(`User wants to upload a ${type} file.`);
          console.log(`User wants to upload file named ${singleFile.name}.`);
          const validTypes = Object.keys(acceptedFileTypes).map(
            (key, _) => key.split("/")[1]
          );
          const validExtensions = Object.values(acceptedFileTypes).flat();
          valid =
            validTypes.includes(type) &&
            validExtensions.some((extension, _) =>
              singleFile.name.endsWith(extension)
            );
        }
        return valid;
      }),
    description: Yup.string().optional(),
  });

  type IUpdateDataFileParamResult = YupToTS<typeof validationSchema>;

  // Set up update handler for updating a data file metadata.
  const handleUpdate = useCallback(
    async (submittedValues: Partial<Omit<IDataFileMetadataEditable, "id">>) => {
      // We're going to submit the updated DataFile record. We'll need to
      // submit it as a multipart formdata request.
      let formData = new FormData();
      formData.append(
        "description",
        submittedValues.description ? submittedValues.description : ""
      );
      // Submit new version of file only if a file instance exists in the input
      // field.
      if (fileRef.current?.files && fileRef.current.files[0]) {
        // Choose just the first file specified, even though our
        // IMySingleFileInputField should automatically limit user to just one
        // file.
        formData.append("file_instance", fileRef.current?.files[0]);
      }

      // Try to submit
      try {
        // PATCH the datafile resource
        let result = await fetcherMultipartFormdata<IDataFileMetadataEditable>(
          `${import.meta.env.VITE_API_URL}/api/v1/datafiles/${datafileId}/`,
          formData,
          "PATCH"
        );

        // Call mutators
        await mutateSingleDataFile();
        await experiment_mutate();

        navigate(-1);
      } catch (err) {
        // If server responded with error, try to show error message to the
        // user.
        if (err instanceof Error) {
          let e: TFetchGeneralError<IUpdateDataFileParamResult> = err;
          setMessage(<MascApiFetchErrorsTypeset error={e} />);
          setShowToast(true);
        }
      }
    },
    []
  );

  return (
    <div className="container">
      {dataFile && !dataFileError && experiment && !experiment_error ? (
        <>
          <h2 className="text-xl font-bold">
            Editing file: {dataFile.filename}
          </h2>
          <div className="my-4">
            <p>
              You are editing the details for this data file. The file name and
              size are calculated from the actual file you uploaded. To change
              the file name, upload a new version of the file with a different
              name.
            </p>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleUpdate}
            onReset={(_values: Omit<IDataFileMetadataEditable, "id"> | {}) => {
              _values = initialValues;
            }}
          >
            {({ isSubmitting, ...props }) => (
              <Form {...props}>
                <div className="flex justify-center">
                  <SimpleToastControlled
                    icon={<FontAwesomeIcon icon={faXmarkCircle} />}
                    message={message}
                    isVisible={showToast}
                    setIsVisible={setShowToast}
                    iconStroke="text-red-500"
                    className="mt-4"
                    iconBg="bg-red-100"
                  />
                </div>
                <div className="flex flex-col gap-y-4">
                  <MySingleFileInputField
                    label="Upload new version of file"
                    name="file_instance"
                    fileRef={fileRef}
                  />
                  <MyTextAreaInput
                    label="Description"
                    name="description"
                    type="text"
                    placeholder="Notes"
                  />
                </div>
                <div className="flex mt-6 gap-x-2">
                  <SubmitButton isSubmitting={isSubmitting} />
                  <GenericButton accentedButton={false} type="reset">
                    Revert to saved
                  </GenericButton>
                  <GenericButton
                    accentedButton={false}
                    onClick={() => {
                      if (experiment) {
                        navigate(
                          `/experiments/${experiment.id}/datafiles/${datafileId}`
                        );
                      } else {
                        navigate(-1);
                      }
                    }}
                  >
                    Cancel
                  </GenericButton>
                </div>
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <LoadingSpinnerInline />
      )}
    </div>
  );
}
