// Card to show basic characterization results on an experiments detail page
import React from "react";
import { MyDateFormat } from "../../lib/date-utils";
import { objectMap, pluralizeByLength } from "../../lib/text-utils";
import { FullSizedPlot } from "../plotting/characterization-plots";

export function CharCardMetadataSection({
  characterization,
}: {
  characterization: ICharacterizationResultWithType;
}) {
  const characterization_type = characterization?.characterization_type;
  return (
    <>
      <div className="text-xl font-bold">{characterization_type?.name}</div>
      <div className="font-medium">{characterization.name}</div>
      <div className="text-sm text-slate-700 dark:text-slate-300">
        <MyDateFormat theDate={characterization.date} />
      </div>
      <div className="mb-2 text-xs italic text-slate-500 dark:text-slate-400">
        {characterization.description}
      </div>
    </>
  );
}

export function KeyValPairDisplay({
  keyValPairData,
}: {
  keyValPairData: IKeyValPair;
}) {
  // Nice way to show key-value pairs for the characterization cards.
  return (
    <div className="flex items-center">
      <div>
        {keyValPairData.friendlyName}
        {": "}
      </div>
      <div className="px-2 py-1 ml-1 text-sm font-semibold text-gray-800 rounded-full bg-slate-300 dark:bg-slate-500 dark:text-gray-200">
        <div>
          {keyValPairData.value} {keyValPairData.units}
        </div>
      </div>
    </div>
  );
}

export function CharCardKeyValContents({
  children,
  data: metadata,
  showHeader = false,
}: {
  children?: React.ReactNode;
  data: any;
  showHeader?: boolean;
}) {
  // Get data specific to XRD results
  return (
    <>
      {showHeader ? (
        <div className="pt-0 mt-4 border-t border-slate-500">
          <div className="text-base font-semibold">Key-Value Pairs</div>
        </div>
      ) : null}

      <div className="flex flex-col mt-1 gap-y-2">
        {objectMap(metadata, (v: IKeyValPair, k: string, i: number) => {
          if (k !== "spectrum") {
            console.log(v);
            return <KeyValPairDisplay key={i} keyValPairData={v} />;
          }
        })}
      </div>
    </>
  );
}

export function CharCardPlotContents({
  children,
  data,
}: {
  children?: React.ReactNode;
  data: any;
}) {
  return (
    <div className="pt-0 mt-4 border-t border-slate-500">
      <div className="text-base font-semibold">Plots</div>
      {data
        ? data.map((single_plot_data: ISinglePlotJsonData, idx: number) => (
            <FullSizedPlot key={idx} rechart_data={single_plot_data} />
          ))
        : null}
    </div>
  );
}

export function CharCardImageContents({
  image_list,
}: {
  image_list: string[];
}) {
  return (
    <div className="pt-0 mt-4 border-t border-slate-500">
      <div className="text-base font-semibold">Images</div>
      {image_list
        ? image_list.map((image_url, idx) => <img key={idx} src={image_url} />)
        : null}
    </div>
  );
}

export default function CharacterizationCard({
  data: characterization,
}: {
  data: ICharacterizationResultWithType;
}) {
  // This will just show the correct characterization. For now we'll just
  // make a card that renders data.

  return (
    <div>
      <div className="w-48 h-48 px-2 py-2 overflow-y-auto transition bg-white rounded-lg shadow-lg dark:bg-slate-600 hover:bg-blue-100 dark:hover:bg-blue-800 ring-0 hover:ring-4 hover:ring-blue-500 hover:cursor-pointer">
        <CharCardMetadataSection characterization={characterization} />
        <CharCardKeyValContents data={characterization.json_keyval_pairs} />
        <div className="mt-2 text-sm font-semibold">
          <p>
            {characterization?.scatter_plots
              ? characterization.scatter_plots.length
              : 0}{" "}
            plot{pluralizeByLength(characterization?.scatter_plots)}
          </p>
          <p>
            {characterization?.image_list
              ? characterization?.image_list.length
              : 0}{" "}
            image{pluralizeByLength(characterization?.image_list)}
          </p>
        </div>
      </div>
    </div>
  );
}
