import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faBroom,
  faFileCsv,
  faFilter,
  faFilterCircleXmark,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table, PaginationState } from "@tanstack/react-table";
import { ChangeEvent, Dispatch, FC, SetStateAction } from "react";
import { UnboundMyTextInput } from "../formik-form-components";
import { ButtonToolbarContainer, GenericButton } from "../buttons";
import CsvDownload from "react-csv-downloader";
import { tableToCsvDownloaderProps } from "./react-csv-download-adapter";
import { cn } from "../../lib/text-utils";

interface ITableToolbox {
  globalFilter: string;
  setGlobalFilter: Dispatch<SetStateAction<string>>;
  table: Table<any>;
  showToolbox: boolean;
  setShowToolbox: Dispatch<SetStateAction<boolean>>;
  showFilters: boolean;
  setShowFilters: Dispatch<SetStateAction<boolean>>;
  pagination: PaginationState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
}
export const TableToolbox: FC<ITableToolbox> = ({
  globalFilter,
  setGlobalFilter,
  table,
  showToolbox,
  setShowToolbox,
  showFilters,
  setShowFilters,
  pagination,
  setPagination,
}) => {
  return showToolbox ? (
    <ButtonToolbarContainer className="px-3 gap-x-2">
      <UnboundMyTextInput
        value={globalFilter}
        updateHandler={setGlobalFilter}
        placeHolder="Full-table text search..."
      />
      <GenericButton
        icon={
          <FontAwesomeIcon
            icon={showFilters ? faFilterCircleXmark : faFilter}
            className="w-4 h-4 text-slate-400"
          />
        }
        onClick={() => {
          setShowFilters((prev) => !prev);
        }}
      >
        {showFilters ? "Hide filters" : "Show filters"}
      </GenericButton>
      <GenericButton
        tooltip_text="Clear all global and column-specific filters and sorting."
        icon={
          <FontAwesomeIcon icon={faBroom} className="w-4 h-4 text-slate-400" />
        }
        onClick={() => {
          table.resetGlobalFilter();

          // Need to also set the global filter state variable (after clearing
          // table's global state) to make the search box clear.
          setGlobalFilter("");

          table.resetColumnFilters();
          table.resetSorting();
        }}
      >
        Clear filters, sorting
      </GenericButton>
      <div className="w-0 h-6 my-auto border-l-2 border-slate-400"></div>
      <PaginationControls
        table={table}
        pagination={pagination}
        setPagination={setPagination}
      />

      <div className="w-0 h-6 my-auto border-l-2 border-slate-400"></div>
      {/* Button to download CSV of filtered rows */}
      <CsvDownload {...tableToCsvDownloaderProps(table)}>
        <GenericButton
          icon={
            <FontAwesomeIcon
              icon={faFileCsv}
              className="w-4 h-4 text-slate-400"
            />
          }
          tooltip_text="Download visible (filtered) rows of table as CSV. Some content, like icons, may not appear exactly the same in the downloaded file."
        >
          Download CSV
        </GenericButton>
      </CsvDownload>
      <div className="grow" />
      <GenericButton
        onClick={() => setShowToolbox(false)}
        icon={
          <FontAwesomeIcon icon={faXmark} className="w-4 h-4 text-slate-400" />
        }
      >
        Close table tools
      </GenericButton>
    </ButtonToolbarContainer>
  ) : null;
};

interface IPaginationControls {
  table: Table<any>;
  expanded?: boolean;
  pagination: PaginationState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
}
export function PaginationControls({
  table,
  expanded = true,
  pagination,
  setPagination,
}: IPaginationControls): JSX.Element {
  return (
    <div className="flex flex-wrap gap-x-1">
      <GenericButton
        icon={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
        onClick={() => table.setPageIndex(0)}
        disabled={!table.getCanPreviousPage()}
        tooltip_text_disabled={expanded ? "Already on first page." : ""}
        className={cn(!expanded ? "p-1 px-2 h-7" : "")}
      >
        First
      </GenericButton>
      <GenericButton
        icon={<FontAwesomeIcon icon={faAngleLeft} />}
        onClick={() => table.previousPage()}
        disabled={!table.getCanPreviousPage()}
        className={cn(!expanded ? "p-1 px-2 h-7" : "")}
        tooltip_text_disabled={expanded ? "No previous page." : ""}
      >
        Previous
      </GenericButton>
      <div className="flex items-center gap-x-1">
        <span>Page</span>
        <UnboundMyTextInput
          type="number"
          placeHolder="Go to page..."
          value={table.getState().pagination.pageIndex + 1}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const targetPageIdx = e.target.value
              ? Number(e.target.value) - 1
              : 0;
            table.setPageIndex(targetPageIdx);
          }}
          className="w-8 p-1 text-center"
          showClearButton={false}
          disabled={table.getPageCount() === 1}
        />
        <span> of {table.getPageCount()}</span>
      </div>
      <GenericButton
        icon={<FontAwesomeIcon icon={faAngleRight} />}
        onClick={() => table.nextPage()}
        disabled={!table.getCanNextPage()}
        className={cn(!expanded ? "p-1 px-2 h-7" : "")}
        tooltip_text_disabled={expanded ? "No next page." : ""}
      >
        Next
      </GenericButton>
      <GenericButton
        icon={<FontAwesomeIcon icon={faAngleDoubleRight} />}
        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
        disabled={!table.getCanNextPage()}
        className={cn(!expanded ? "p-1 px-2 h-7" : "")}
        tooltip_text_disabled={expanded ? "Already on last page." : ""}
      >
        Last
      </GenericButton>
      {/* Select box for selecting page size. */}
      {pagination && setPagination ? (
        <select
          id="select-num-papers"
          className={cn(
            "px-3 py-2 text-sm transition border border-gray-300 rounded-lg shadow-sm outline-none pr-7 focus:ring-blue-400 focus:ring-2 shadow-gray-300 dark:shadow-gray-700 dark:border-gray-700 hover:border-gray-400 dark:hover:border-gray-600 hover:shadow-md dark:bg-slate-500 dark:text-slate-100 dark:placeholder:text-slate-200",
            !expanded ? "py-1" : ""
          )}
          value={pagination.pageSize}
          onChange={(e) =>
            setPagination({
              ...pagination,
              pageIndex: 0,
              pageSize: parseInt(e.target.value),
            })
          }
        >
          {[5, 10, 15, 20, 25, 50, 100].map((n) => (
            <option value={n} key={n}>
              {n} rows/page
            </option>
          ))}
        </select>
      ) : null}
    </div>
  );
}
