// Sidebar-Detail UI for experiment editor

import { faFile } from "@fortawesome/free-regular-svg-icons";
import {
  faChartLine,
  faCircleInfo,
  faDiagramProject,
  faInfo,
  faMicroscope,
  faTableList,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createContext } from "react";
import { Outlet, useParams } from "react-router-dom";
import { KeyedMutator } from "swr";
import { SidebarDetailHeader } from "../../components/sidebar-detail-ui/sidebar-detail-header";
import SidebarDetailUI from "../../components/sidebar-detail-ui/sidebar-detail-main";
import LoadingSpinnerInline from "../../components/spinners";
import {
  useApiModelDetailView,
  useApiModelListView,
} from "../../lib/api-mutate-hooks";

// Have context so we can pass things like mutators through to char result
// components, etc.
interface IExperimentContext {
  experiment: IExperimentSlim | undefined;
  experiment_error: any;
  experiment_mutate: KeyedMutator<IExperimentSlim>;
  characterization_results_sidebar_mutate: KeyedMutator<
    ICharacterizationResultExtra[]
  >;
  procedures_sidebar_mutate: KeyedMutator<IProcedureSimple[]>;
}
export const ExperimentContext = createContext<IExperimentContext>(
  {} as IExperimentContext
);

interface IExperimentSlim extends IExperiment {
  characterization_results: ICharacterizationResult[];
}

export default function ExperimentalDetailPageNew() {
  // Get experiment ID from URL
  const params = useParams<{ id: string }>();
  const id = params.id ? parseInt(params.id) : undefined;
  const {
    data: experiment,
    error: experiment_error,
    mutate: experiment_mutate,
  } = useApiModelDetailView<IExperimentSlim>({
    resourceName: "experiments",
    id,
    expand: ["characterization_results"],
    shouldGetData: typeof id !== "undefined",
  });

  // Also need basic info on procedures for the experiment.
  const {
    data: procedures_simple,
    error: procedures_simple_errors,
    mutate: procedures_sidebar_mutate,
  } = useApiModelListView<IProcedureSimple>({
    resourceName: "procedures_simple",
    queryParams: { experiment: String(id) },
    shouldGetData: true,
  });

  // Dependent query to get characterization results with char result type and
  // scatter plot metadata.
  const {
    data: charResults,
    error: charResultsError,
    mutate: charResultsMutate,
  } = useApiModelListView<ICharacterizationResultExtra>({
    resourceName: "characterization_results",
    expand: ["characterization_type", "scatter_plots"],
    shouldGetData: !!(
      experiment &&
      !experiment_error &&
      experiment.characterization_results &&
      experiment.characterization_results.length > 0
    ),
    queryParams: {
      id__in: String(
        experiment?.characterization_results.map(
          (charResult, _) => charResult.id
        )
      ),
    },
  });

  // Need to generate menu spec based on what data we have for this particular
  // experiment

  // Generate spec from most detailed levels first and then to most general
  // levels.
  const getCharacterizationPlotSpecs = ({
    char,
  }: {
    char: ICharacterizationResultExtra;
  }): (ISingleMenuItemSpec | IMultiLevelMenuItemSpec)[] => {
    const overviewItem = {
      icon: <FontAwesomeIcon icon={faInfo} />,
      title: "Overview",
      to: `characterizations/${char.id}/plots`,
      highlightEdit: false,
    };

    const plotItems = char.scatter_plots.map((plot, idx) => {
      return {
        icon: <FontAwesomeIcon icon={faChartLine} />,
        title: `Plot ${idx + 1}: ${plot.name}`,
        to: `characterizations/${char.id}/plots/${plot.id}`,
        highlightEdit: true,
      };
    });

    return [overviewItem, ...plotItems];
  };

  const getCharacterizationResultItemSpecs = ({
    char,
  }: {
    char: ICharacterizationResultExtra;
  }): (ISingleMenuItemSpec | IMultiLevelMenuItemSpec)[] => {
    return [
      {
        icon: <FontAwesomeIcon icon={faInfo} />,
        title: "Overview",
        to: `characterizations/${char.id}/overview`,
      },
      {
        icon: <FontAwesomeIcon icon={faTableList} />,
        title: "Custom Properties",
        to: `characterizations/${char.id}/keyval-pairs`,
      },
      {
        icon: <FontAwesomeIcon icon={faChartLine} />,
        title: "Plots",
        to: `characterizations/${char.id}/plots`,
        items: getCharacterizationPlotSpecs({ char }),
      },
      {
        icon: <FontAwesomeIcon icon={faFile} />,
        title: "Data Files",
        to: `characterizations/${char.id}/datafiles`,
        items: [],
      },
    ];
  };

  const getProcedureItemSpecs = ({
    experiment,
  }: {
    experiment: IExperiment;
  }): (ISingleMenuItemSpec | IMultiLevelMenuItemSpec)[] => {
    const overviewItem = {
      icon: <FontAwesomeIcon icon={faInfo} />,
      title: "Overview",
      to: `procedures/overview`,
    };

    const listItems =
      procedures_simple && !procedures_simple_errors
        ? procedures_simple.map((procedure, idx: number) => ({
            icon: <FontAwesomeIcon icon={faDiagramProject} />,
            title: `Procedure #${idx + 1}: ${procedure.name}`,
            to: `procedures/${procedure.id}`,
          }))
        : [];

    return [overviewItem, ...listItems];
  };

  const getCharacterizationResultsListSpecs = (): (
    | ISingleMenuItemSpec
    | IMultiLevelMenuItemSpec
  )[] => {
    let results: (ISingleMenuItemSpec | IMultiLevelMenuItemSpec)[] = [
      {
        icon: <FontAwesomeIcon icon={faInfo} />,
        title: "Overview",
        to: `characterizations/overview`,
      },
    ];

    if (!!(charResults && !charResultsError && charResults.length > 0)) {
      results.push(
        ...charResults.map((char, charIdx) => ({
          icon: <FontAwesomeIcon icon={faMicroscope} />,
          title: `${charIdx + 1}: ${char?.name} (${
            char?.characterization_type.name
          })`,
          to: `characterizations/${char.id}`,
          items: getCharacterizationResultItemSpecs({ char }),
        }))
      );
    }

    return results;
  };

  const getExperimentItemSpecs = ({
    experiment,
  }: {
    experiment: IExperiment;
  }): (ISingleMenuItemSpec | IMultiLevelMenuItemSpec)[] => {
    return [
      {
        icon: <FontAwesomeIcon icon={faCircleInfo} />,
        title: "Overview",
        to: "overview",
        items: [],
      },
      {
        icon: <FontAwesomeIcon icon={faDiagramProject} />,
        title: "Procedures",
        to: "procedures",
        items: getProcedureItemSpecs({ experiment }),
      },
      {
        icon: <FontAwesomeIcon icon={faFile} />,
        title: "All Data Files",
        to: "datafiles",
        items: [],
      },
      {
        icon: <FontAwesomeIcon icon={faMicroscope} />,
        title: "Characterization Results",
        to: "characterizations",
        items: getCharacterizationResultsListSpecs(),
      },
    ];
  };

  const menuSpec = experiment
    ? getExperimentItemSpecs({ experiment })
    : [
        {
          icon: <FontAwesomeIcon icon={faCircleInfo} />,
          title: "Loading...",
          to: "overview",
          items: [],
        },
      ];

  const header = (
    <SidebarDetailHeader
      backBtnLabel="Back to experiment list"
      title={experiment?.name ? experiment.name : ""}
      backBtnTo="/experiments"
      itemType="experiment"
    />
  );

  return experiment &&
    !experiment_error &&
    procedures_simple &&
    !procedures_simple_errors ? (
    <ExperimentContext.Provider
      value={{
        experiment,
        experiment_error,
        experiment_mutate,
        characterization_results_sidebar_mutate: charResultsMutate,
        procedures_sidebar_mutate: procedures_sidebar_mutate,
      }}
    >
      <SidebarDetailUI menuItems={menuSpec} header={header}>
        <Outlet />
      </SidebarDetailUI>
    </ExperimentContext.Provider>
  ) : (
    <SidebarDetailUI
      menuItems={menuSpec}
      header={
        <div className="flex justify-center">
          <LoadingSpinnerInline />
        </div>
      }
    />
  );
}
