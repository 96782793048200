// Page to show publications that are manually pinned or associated with this
// group.
import { useParams } from "react-router-dom";
import useSWR, { mutate, useSWRConfig } from "swr";
import LoadingSpinnerInline from "../../components/spinners";
import {
  IPublicationWithFullAuthors,
  PublicationsTable,
} from "../../components/groups/publications-table";
import fetcher, { fetcherPatch } from "../../lib/fetcher";
import { HTMLAttributes, forwardRef, useState } from "react";
import { SimpleToastControlled } from "../../components/message-indicators";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { cn } from "../../lib/text-utils";
import { MyMultiSelectInput } from "../../components/formik-react-select";
import { GenericButton, SubmitButton } from "../../components/buttons";
import { useCurrentKnoxUser } from "../../lib/auth";
import { useApiModelListView } from "../../lib/api-mutate-hooks";

export function GroupDetailPinnedPublicationsOutlet() {
  // Get group ID
  const params = useParams<{ id: string }>();
  const id = params.id && parseInt(params.id);

  // Need basic group information

  interface IGroupMetadataWithUsers extends IGroupMetadata {
    admins: IUser[];
    members: IUser[];
  }
  interface IGroupWithUsers extends IGroup {
    group_metadata: IGroupMetadataWithUsers;
  }

  const { data: group, error: groupError } = useSWR<IGroupWithUsers>(
    `${import.meta.env.VITE_API_URL}/api/v1/groups/${id}/`
  );

  // Also need currently logged-in user
  const { currentKnoxUser, currentKnoxUserError } = useCurrentKnoxUser({
    shouldGetData: true,
  });

  // Determine if user is group admin
  const isGroupAdmin =
    currentKnoxUser &&
    !currentKnoxUserError &&
    group &&
    group.group_metadata.admins.some(
      (admin, _) => admin.id === currentKnoxUser.pk
    );

  // Then, need dependent query to fetch just the pinned publications
  const { data: pinnedPublications, error: pinnedPublicationsError } =
    useApiModelListView<IPublicationWithFullAuthors>({
      resourceName: "publications",
      expand: ["authors"],
      shouldGetData:
        group && !groupError && group.group_metadata.papers.length > 0,
      queryParams: { id__in: String(group?.group_metadata.papers) },
    });

  // Now, just show the large table of publications
  return group && !groupError ? (
    <div className="container">
      <h2 className="text-xl font-bold">Pinned publications</h2>
      <p className="my-3 text-sm italic prose text-slate-500 dark:text-slate-300">
        Pinned publications are publications manually assigned to a group. Use
        this feature to highlight publications you want featured with your
        group.
      </p>
      {id && isGroupAdmin ? (
        <AddPinnedPublicationSingleLineForm
          current_publications={pinnedPublications}
          group_id={id}
          className="mt-6 mb-4"
        />
      ) : null}
      {group.group_metadata.papers.length > 0 &&
      pinnedPublications &&
      !pinnedPublicationsError ? (
        <PublicationsTable
          publications={pinnedPublications}
          isGroupAdmin={isGroupAdmin}
          removePubHandler={async (info) => {
            if (group && group.id) {
              const result = await submitPinnedPublicationsChanges({
                group_id: group.id,
                operation: "remove",
                publication_ids: [info.row.original.id],
              });
              await mutate(
                `${import.meta.env.VITE_API_URL}/api/v1/groups/${id}/`
              );
            }
          }}
        />
      ) : (
        <p className="mt-4 text-md text-slate-500 dark:text-slate-300">
          No publications here yet.
        </p>
      )}
    </div>
  ) : (
    <LoadingSpinnerInline />
  );
}

export function GroupDetailRelatedPublicationsOutlet() {
  // Get group ID
  const params = useParams<{ id: string }>();
  const id = params.id && parseInt(params.id);

  // Need basic group information
  const { data: group, error: groupError } = useSWR<IGroup>(
    `${import.meta.env.VITE_API_URL}/api/v1/groups/${id}/`
  );

  // Then, need dependent query to fetch just the related publications.
  const { data: relatedPublications, error: relatedPublicationsError } =
    useApiModelListView<IPublicationWithFullAuthors>({
      resourceName: "publications",
      expand: ["authors"],
      shouldGetData:
        group &&
        !groupError &&
        group.group_metadata.related_publications.length > 0,
      queryParams: {
        id__in: String(group?.group_metadata.related_publications),
      },
    });

  // Now, just show the large table of publications
  return group && !groupError ? (
    <div className="container">
      <h2 className="text-xl font-bold">Related publications</h2>
      <p className="text-sm italic prose text-slate-500 dark:text-slate-300">
        This list of related publications is derived from publications
        associated with experiments this group owns. Edit those experiments to
        change which publications show up here.
      </p>
      {group.group_metadata.related_publications.length > 0 &&
      relatedPublications &&
      !relatedPublicationsError ? (
        <PublicationsTable publications={relatedPublications} />
      ) : (
        <p className="mt-4 text-md text-slate-500 dark:text-slate-300">
          No publications here yet.
        </p>
      )}
    </div>
  ) : (
    <LoadingSpinnerInline />
  );
}
interface ISubmitPinnedPublicationChanges {
  group_id: number;
  publication_ids: number[];
  operation: "add" | "remove";
}
interface ISubmitPinnedPublicationChangesResult {
  detail: string;
}
const submitPinnedPublicationsChanges: (
  arg0: ISubmitPinnedPublicationChanges
) => Promise<ISubmitPinnedPublicationChangesResult | undefined> = async ({
  group_id,
  publication_ids,
  operation,
}) => {
  const publication_id_str = publication_ids.join(",");
  console.log(
    `Going to ${operation} publication IDs`,
    publication_id_str,
    "as pinned publications relative to group",
    group_id
  );
  try {
    return await fetcherPatch<{}, ISubmitPinnedPublicationChangesResult>(
      `${
        import.meta.env.VITE_API_URL
      }/api/v1/groups/${group_id}/pinned_publications/?publication_ids=${publication_id_str}`,
      {},
      operation === "add" ? "POST" : "DELETE"
    );
  } catch (error) {
    console.log(error);
    return { detail: "request-error" };
  }
};

export interface IAddPinnedPublicationSingleLineForm
  extends HTMLAttributes<HTMLFormElement> {
  current_publications?: IPublication[];
  group_id: number;
}

const AddPinnedPublicationSingleLineForm = forwardRef<
  HTMLFormElement,
  IAddPinnedPublicationSingleLineForm
>(({ className, current_publications, group_id, ...props }, ref) => {
  const [showForm, setShowForm] = useState(false);

  // Get all publications available to be pinned to the group
  const current_publication_ids = current_publications
    ? current_publications.map((institution) => institution.id)
    : [];
  const current_publication_ids_str = current_publication_ids.join(",");
  const { data: allPublications, error: allPublicationsError } = useSWR<
    Pick<IPublication, "id" | "title" | "doi">[]
  >(
    `${
      import.meta.env.VITE_API_URL
    }/api/v1/publications/?fields=id,title,doi&-id__in=${current_publication_ids_str}`
  );

  const publicationChoices = allPublications
    ? allPublications
        .filter(
          (publication) => !current_publication_ids.includes(publication.id)
        )
        .map((publication) => ({
          id: publication.id,
          name: `${publication.title} (${publication.doi})`,
        }))
    : [];

  // Set up little Formik form for adding new group publications
  const [formKey, setFormKey] = useState(1);
  const initialValues = { publications_to_add: [] };
  const validationSchema = Yup.object().shape({
    publications_to_add: Yup.array()
      .of(Yup.number())
      .required("Must specify publications to add")
      .min(1, "Must specify at least one publication to add."),
  });

  const [resultMsg, setResultMsg] = useState("");
  const { mutate } = useSWRConfig();
  const resultMsgHash: { [x: string]: string } = {
    ok: `Successfully added publication(s) to group!`,
    "One or more of the specified institutions does not exist.":
      "One or more of the specified institutions does not exist.",
    "request-error":
      "There was an error processing your request. Please try again later.",
  };
  const [toastVisible, setToastVisible] = useState(false);

  return allPublications && !allPublicationsError ? (
    <>
      <SimpleToastControlled
        message={resultMsg}
        icon={<FontAwesomeIcon icon={faCircleInfo} />}
        isVisible={toastVisible}
        setIsVisible={setToastVisible}
        className="mx-auto"
      />
      <Formik
        key={formKey}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          const publication_ids = values.publications_to_add;
          const result = await submitPinnedPublicationsChanges({
            group_id,
            publication_ids,
            operation: "add",
          });
          await mutate(
            `${import.meta.env.VITE_API_URL}/api/v1/groups/${group_id}/`
          );
          setSubmitting(false);
          console.log("Got response", result);

          const resultsMsg =
            result && result.detail
              ? resultMsgHash[result.detail]
                ? resultMsgHash[result.detail]
                : "Unknown response message"
              : "Unknown error";

          setResultMsg(resultsMsg);
          setToastVisible(true);
          setShowForm(false);
        }}
        onReset={(values) => {
          values = initialValues;
        }}
      >
        {({ isSubmitting, ...formikFormprops }) => (
          <Form ref={ref} {...formikFormprops} {...props}>
            <div
              className={cn(
                "gap-y-2 flex",
                className,
                showForm ? "flex-col" : ""
              )}
            >
              {showForm ? (
                <>
                  <MyMultiSelectInput
                    choices={publicationChoices}
                    label="Publications to pin to this group:"
                    name="publications_to_add"
                    type="number"
                  />
                  <div className="flex gap-x-4">
                    <SubmitButton isSubmitting={isSubmitting} />
                    <GenericButton type="reset" accentedButton={false}>
                      Clear
                    </GenericButton>
                    <GenericButton
                      type="reset"
                      onClick={() => {
                        // Want to both close the form and force it to reset.
                        setShowForm(false);
                        setFormKey((key) => key + 1);
                      }}
                    >
                      Close
                    </GenericButton>
                  </div>
                </>
              ) : (
                <GenericButton
                  icon={<FontAwesomeIcon icon={faPlus} />}
                  accentedButton
                  onClick={() => setShowForm(true)}
                >
                  Add publications
                </GenericButton>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  ) : (
    <LoadingSpinnerInline />
  );
});
