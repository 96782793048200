// Stuff to allow display of chemical compositions.

import { MathJax } from "better-react-mathjax";
import { FC } from "react";

interface IChemicalFormulaMathJax {
  formulaJson: Record<ChemicalElementSymbol, number>;
}

export const ChemicalFormulaMathJax: FC<IChemicalFormulaMathJax> = ({
  formulaJson,
}) => {
  const formulaStr = Object.entries(formulaJson)
    .map(([element, amount], _) => `${element}_{${amount}}`)
    .join("");
  return <MathJax inline>{`\\(\\ce{${formulaStr}}\\)`}</MathJax>;
};
