// Wrapper for react-select multi-select component for use in Formik forms.

import { faChevronDown, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select, {
  ClearIndicatorProps,
  DropdownIndicatorProps,
  GroupBase,
  MultiValueRemoveProps,
  OnChangeValue,
  Props,
  components,
} from "react-select";
import { cn } from "../lib/text-utils";
import { ClassAttributes, FC, SelectHTMLAttributes } from "react";
import { ErrorMessage, useField, useFormikContext } from "formik";

// Thanks to
// https://www.jussivirtanen.fi/writing/styling-react-select-with-tailwind for
// all the styling!
const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon icon={faChevronDown} className="w-3 h-3" />
    </components.DropdownIndicator>
  );
};

const ClearIndicator = (props: ClearIndicatorProps) => {
  return (
    <components.ClearIndicator {...props}>
      <FontAwesomeIcon icon={faXmark} className="w-4 h-4" />
    </components.ClearIndicator>
  );
};

const MultiValueRemove = (props: MultiValueRemoveProps) => {
  return (
    <components.MultiValueRemove {...props}>
      <FontAwesomeIcon icon={faXmark} className="w-4 h-4" />
    </components.MultiValueRemove>
  );
};

const controlStyles = {
  base: "border rounded-lg bg-white hover:cursor-pointer text-sm shadow-sm shadow-gray-300 dark:shadow-gray-700 border border-gray-300 dark:border-gray-700 hover:border-gray-400 dark:hover:border-gray-600 hover:shadow-md transition dark:bg-slate-500",
  focus: "ring-2 ring-blue-400",
  nonFocus: "border-gray-300 hover:border-gray-400",
};
const placeholderStyles = "text-slate-500 dark:text-slate-300 pl-1 py-0.5";
const selectInputStyles = "pl-1 py-0.5";
const valueContainerStyles = "p-1 gap-1";
const singleValueStyles = "leading-7 ml-1";
const multiValueStyles =
  "bg-slate-100 dark:bg-slate-600 border border-slate-200 dark:border-slate-700 rounded-md items-center py-0.5 pl-2 pr-1 gap-1.5";
const multiValueLabelStyles = "leading-6 py-0.5";
const multiValueRemoveStyles =
  "border border-slate-200 bg-slate-100 dark:bg-slate-300 hover:bg-red-50 dark:hover:bg-red-100 hover:text-red-800 text-slate-500 hover:border-red-300 rounded-md";
const indicatorsContainerStyles = "p-1 gap-1";
const clearIndicatorStyles =
  "text-slate-500 p-1 rounded-md hover:bg-red-50 hover:text-red-800 dark:text-slate-300 dark:hover:bg-red-300 dark:hover:text-red-800";
const indicatorSeparatorStyles = "bg-slate-300 dark:bg-slate-600";
const dropdownIndicatorStyles =
  "p-1 hover:bg-slate-100 dark:hover:bg-slate-700 text-slate-500 dark:text-slate-300 rounded-md hover:text-black";
const menuStyles =
  "p-1 mt-2 border border-slate-200 dark:border-slate-600 bg-white dark:bg-slate-500 rounded-lg";
const groupHeadingStyles = "ml-3 mt-2 mb-1 text-slate-500 text-sm";
const optionStyles = {
  base: "hover:cursor-pointer px-3 py-2 rounded",
  focus:
    "bg-slate-200 dark:bg-slate-700 active:bg-slate-400 dark:active:bg-slate-600",
  selected: "text-slate-400",
};
const noOptionsMessageStyles =
  "text-slate-500 p-2 bg-slate-50 border border-dashed border-slate-200 rounded-sm";

// interface SelectProps<
//   Option = unknown,
//   IsMulti extends boolean = false,
//   Group extends GroupBase<Option> = GroupBase<Option>
// > {}

export function ReactSelectTailwind<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: Props<Option, IsMulti, Group>) {
  return (
    <Select
      hideSelectedOptions={false}
      unstyled
      styles={{
        input: (base) => ({
          ...base,
          "input:focus": {
            boxShadow: "none",
          },
        }),
        // On mobile, the label will truncate automatically, so we want to
        // override that behaviour.
        multiValueLabel: (base) => ({
          ...base,
          whiteSpace: "normal",
          overflow: "visible",
        }),
        control: (base) => ({
          ...base,
          transition: "none",
        }),
      }}
      // @ts-ignore: Possible bug raising weird type error on ClearIndicator.
      components={{ DropdownIndicator, ClearIndicator, MultiValueRemove }}
      classNames={{
        control: ({ isFocused }) =>
          cn(
            isFocused ? controlStyles.focus : controlStyles.nonFocus,
            controlStyles.base
          ),
        placeholder: () => placeholderStyles,
        input: () => selectInputStyles,
        valueContainer: () => valueContainerStyles,
        singleValue: () => singleValueStyles,
        multiValue: () => multiValueStyles,
        multiValueLabel: () => multiValueLabelStyles,
        multiValueRemove: () => multiValueRemoveStyles,
        indicatorsContainer: () => indicatorsContainerStyles,
        clearIndicator: () => clearIndicatorStyles,
        indicatorSeparator: () => indicatorSeparatorStyles,
        dropdownIndicator: () => dropdownIndicatorStyles,
        menu: () => menuStyles,
        groupHeading: () => groupHeadingStyles,
        option: ({ isFocused, isSelected }) =>
          cn(
            isFocused && optionStyles.focus,
            isSelected && optionStyles.selected,
            optionStyles.base
          ),
        noOptionsMessage: () => noOptionsMessageStyles,
      }}
      {...props}
    />
  );
}

type TSelectBase = SelectHTMLAttributes<HTMLSelectElement> &
  ClassAttributes<HTMLSelectElement>;

type TSingleSelectOption = { id: number; name: string };

interface IMultiSelectInputOption {
  label: string;
  value: string;
  options?: IMultiSelectInputOption[];
}

interface IMyMultiSelectInput extends TSelectBase {
  label: string;
  name: string;
  type: string;
  choices: TSingleSelectOption[];
}

// Integration of react-select with Formik. Thanks go
// https://gist.github.com/hubgit/e394e9be07d95cd5e774989178139ae8?permalink_comment_id=3677960#gistcomment-3677960
// and others.
export const MyMultiSelectInput: FC<IMyMultiSelectInput> = (props) => {
  const { id, name, label, choices } = props;
  const [field, meta, helpers] = useField<number[]>(props);

  // On-change handler for multi-valued field
  const onChange = (options: OnChangeValue<IMultiSelectInputOption, true>) => {
    helpers.setValue(options.map((option) => parseInt(option.value)));
  };

  const getValue = () => {
    if (choices && typeof field.value !== "undefined" && field.value) {
      return choices
        .filter((choice) => field.value.indexOf(choice.id) >= 0)
        .map((choice) => ({ label: choice.name, value: String(choice.id) }));
    } else {
      return [];
    }
  };

  return (
    <div className={props.className}>
      <label
        htmlFor={id || name}
        className="block pb-2 text-sm font-semibold text-gray-600 dark:text-gray-400"
      >
        {label}
      </label>
      <div
        className={
          meta.touched && meta.error
            ? "ring-2 ring-red-800 dark:ring-red-300 rounded-lg"
            : ""
        }
      >
        <ReactSelectTailwind
          options={choices.map((choice) => ({
            value: String(choice.id),
            label: choice.name,
          }))}
          isMulti
          value={getValue()}
          onChange={onChange}
          onBlur={() => helpers.setTouched(true)}
          closeMenuOnSelect={false}
        />
      </div>

      {meta.touched && meta.error && (
        <div className="pb-3 mt-2 text-sm font-semibold text-red-800 border-none dark:text-red-300">
          {meta.error}
        </div>
      )}
    </div>
  );
};

interface ISingleSelectInputOption {
  label: string;
  value: string;
  options?: ISingleSelectInputOption[];
}

interface IMySingleSelectInput extends TSelectBase {
  label: string;
  name: string;
  type: string;
  choices: TSingleSelectOption[];
}

// Integration of react-select with Formik, single-valued version. Thanks go
// https://gist.github.com/hubgit/e394e9be07d95cd5e774989178139ae8?permalink_comment_id=3677960#gistcomment-3677960
// and others.
export const MySingleSelectInput: FC<IMySingleSelectInput> = (props) => {
  const { id, name, label, choices } = props;
  const [field, meta, helpers] = useField<number>(props);

  // On-change handler for single-valued field
  const onChange = (option: OnChangeValue<ISingleSelectInputOption, false>) => {
    if (option && option.value) {
      helpers.setValue(parseInt(option.value));
    }
  };

  const getValue = () => {
    if (choices && typeof field.value !== "undefined" && field.value) {
      return choices
        .filter((choice) => field.value === choice.id)
        .map((choice) => ({ label: choice.name, value: String(choice.id) }))[0];
    } else {
      return null;
    }
  };

  return (
    <div className={props.className}>
      <label
        htmlFor={id || name}
        className="block pb-2 text-sm font-semibold text-gray-600 dark:text-gray-400"
      >
        {label}
      </label>
      <div
        className={
          meta.touched && meta.error
            ? "ring-2 ring-red-800 dark:ring-red-300 rounded-lg"
            : ""
        }
      >
        <ReactSelectTailwind
          options={choices.map((choice) => ({
            value: String(choice.id),
            label: choice.name,
          }))}
          isMulti={false}
          closeMenuOnSelect
          value={getValue()}
          onChange={onChange}
          onBlur={() => helpers.setTouched(true)}
          className="mb-3"
        />
      </div>

      {meta.touched && meta.error && (
        <div className="pb-3 mt-2 text-sm font-semibold text-red-800 border-none dark:text-red-300">
          {meta.error}
        </div>
      )}
    </div>
  );
};
